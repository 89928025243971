import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SimpleInputField from '../../../components/SimpleInputField/SimpleInputField';
import useAddCandidateDetailStyles from '../AddCandidateDetailStyles';

interface AddDocumentFormProps {
  readonly handleSubmit: any;
  readonly formRef: any;
  readonly formState: any;
  readonly isRequired?: boolean;
  readonly label?: string;
  readonly name?: string;
  readonly type?: string;
  readonly acceptedFile?: Array<string>;
}

const AddDocumentForm: React.FC<AddDocumentFormProps> = ({
  handleSubmit,
  formRef,
  formState,
  isRequired = true,
  label = 'Upload resume',
  name = 'resume',
  type = '.pdf,.doc,.docx',
  acceptedFile = ['.pdf', '.doc', '.docx'],
}: any) => {
  const styles = useAddCandidateDetailStyles();
  const validationSchema = Yup.object().shape({
    documentFile: Yup.mixed()
      .test('fileType', 'Please upload a PDF file.', function (value) {
        if (!value) {
          return true;
        }
        const acceptedFiles = acceptedFile;
        const fileExtension = value.name.split('.').pop().toLowerCase();
        return acceptedFiles.includes(`.${fileExtension}`);
      })
      .required('Please upload a file.'),
  });
  return (
    <>
      <Formik
        initialValues={formState}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={formRef}
        enableReinitialize>
        {(formik) => {
          const { setFieldValue, dirty, isValid, values } = formik;
          return (
            <>
              <Form>
                <Grid container rowSpacing={2} spacing={1}>
                  <Grid item xs={12}>
                    <SimpleInputField
                      name={name}
                      label={label}
                      variant="standard"
                      type="file"
                      onChange={(evt: any) => {
                        setFieldValue(`documentFile`, evt.currentTarget.files[0]);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ accept: type }}
                      fullWidth
                      required={isRequired}
                    />
                  </Grid>
                  <Grid item>
                    <Box>
                      {values.documentName && (
                        <Box className={styles.fileDetailsWrapper}>
                          <Typography className={styles.uploadText}>Uploaded file: </Typography>
                          {values.documentName.split('.').pop().toLowerCase() === 'pdf' ? (
                            <PictureAsPdfIcon color="primary" className={styles.pdfIcon} />
                          ) : (
                            <DescriptionIcon color="primary" className={styles.docIcon} />
                          )}
                          <Typography className={styles.uploadText}>
                            {values.documentName}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Button disabled={!dirty || !isValid} variant="contained" type="submit">
                  Upload
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default AddDocumentForm;
