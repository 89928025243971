import { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useHomeStyles from './HomeStyles';
import I18nKey from '../../translations/I18nKey';
import policy from '../../assets/icons/policy.svg';
import appraisal from '../../assets/icons/appraisal.svg';
import orgview from '../../assets/icons/orgview.svg';
import emailalias from '../../assets/icons/emailalias.svg';
import document from '../../assets/icons/document.svg';
import family from '../../assets/icons/policy/harassmentWorkplace.svg';
import holidaycal from '../../assets/icons/holidaycal.svg';
import employebenifit from '../../assets/icons/employebenifit.svg';
import claimdevice from '../../assets/icons/claimdevice.svg';
import faq from '../../assets/icons/faq.svg';
import leave from '../../assets/icons/leave.svg';
import { PATHS as PolicyPaths } from '../../api/Policy/policyAPIs';
import { PATHS as AppraisalPaths } from '../../api/Appraisal/appraisalAPIs';
import { PATHS as OrgViewPaths } from '../../api/Employee/employeeAPIs';
import { PATHS as SearchAll } from '../../api/Search/searchAPIs';
import { PATHS as FamilyPaths } from '../../api/Family/familyAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';

const QuickLinks = () => {
  const intl = useIntl();
  const styles = useHomeStyles();
  const { employeeUid } = useContext(GlobalContext);

  const modules: any[] = [
    {
      icon: policy,
      label: intl.formatMessage({
        id: I18nKey.POLICY,
      }),
      link: '/policy',
      module: PolicyPaths.POLICY,
    },
    {
      icon: emailalias,
      label: intl.formatMessage({
        id: I18nKey.EMAIL_ALIASES,
      }),
      link: '/home/email-aliases',
    },
    {
      icon: employebenifit,
      label: intl.formatMessage({
        id: I18nKey.EMPLOYEE_BENIFIT,
      }),
      module: SearchAll.EMPLOYEE,
    },
    {
      icon: orgview,
      label: intl.formatMessage({
        id: I18nKey.ORG_VIEW,
      }),
      module: OrgViewPaths.PROFILE,
      link: '/profile/team',
    },
    {
      icon: holidaycal,
      label: intl.formatMessage({
        id: I18nKey.HOLIDAY,
      }),
      link: '/home/holiday-calendar',
    },
    {
      icon: claimdevice,
      label: intl.formatMessage({
        id: I18nKey.CLAIM_DEVICE,
      }),
      module: SearchAll.EMPLOYEE,
    },
    {
      icon: faq,
      label: intl.formatMessage({
        id: I18nKey.FAQ,
      }),
      module: SearchAll.EMPLOYEE,
    },
    {
      icon: leave,
      label: intl.formatMessage({
        id: I18nKey.TIMESHEET_TITLE,
      }),
      module: SearchAll.EMPLOYEE,
      link: '/timesheet',
    },
    {
      icon: appraisal,
      label: intl.formatMessage({
        id: I18nKey.APPRAISAL_TITLE,
      }),
      module: AppraisalPaths.GET_APPRAISAL,
      link: '/appraisal',
    },
    {
      icon: document,
      label: intl.formatMessage({
        id: I18nKey.PROFILE_MY_DOCUMENTS,
      }),
      module: SearchAll.EMPLOYEE,
      link: `/profile/document/${employeeUid}`,
    },
    {
      icon: family,
      label: intl.formatMessage({
        id: I18nKey.PROFILE_MY_FAMILY,
      }),
      module: FamilyPaths.GET_BASIC_INFO('id'),
      link: `/myFamily/${employeeUid}`,
    },
  ];

  return (
    <>
      <Box className={styles.header}>Quick links</Box>
      <Box className={styles.quickLinksWrapper}>
        <Grid container spacing={1}>
          {modules.map(({ icon, label, link }) => (
            <Grid key={link} item xs={12} sm={2} className={styles.itemWrapper}>
              <Link to={link}>
                <Box className={styles.routeItem}>
                  <Box component="img" src={icon} alt={label} className={styles.ModuleIcon} />
                  <Typography className={styles.label}>{label}</Typography>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default QuickLinks;
