/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-boolean-value */
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import OnboardingCLient from '../../api/OnBoarding/onboardingAPIs';
import Datatable from '../../components/Datatable/Datatable';
import {
  initialSort,
  getColumns,
  dynamicColumns,
  tableRowTotalField,
  uid,
  getInitialVisibilityModel,
  initialShowCols,
} from './NewJoineeListConfig';
import { generateRows } from '../../components/Datatable/datatableUtils';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { getFullName } from '../../utils/utils';

const NewJoineeList: React.FC<any> = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const { showSnackbar } = useDisplaySnackbar();
  const [isLoading, setLoading] = useState(false);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }),
  );

  const generateEmployeeRows = (rows: any) => {
    const updatedTableRows: any = [];
    rows.forEach((row: any) => {
      const newRow: any = {};
      newRow.uid = row.employee.uid;
      newRow.personalEmail = row.employee.personalEmail;
      newRow.employeeName = getFullName(row.employee);
      newRow.linkedinUrl = row.employee.linkedinUrl;
      newRow.currentCity = row.employee.currentCity;
      updatedTableRows.push(newRow);
    });
    return updatedTableRows;
  };

  useEffect(() => {
    setLoading(true);
    const status = 'submitted';
    OnboardingCLient.getNewJoineeList(status)
      .then((response: any) => {
        const rows = generateEmployeeRows(response.data);
        const updatedRows = generateRows(rows, dynamicColumns, tableRowTotalField, uid);
        setTableRows(updatedRows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  }, []);

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <>
      <Datatable
        loading={isLoading}
        columns={getColumns()}
        rows={tableRows}
        columnVisibility={true}
        initialSort={initialSort as any}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        showExportButton
        exportedFileName={`NewJoinee_List_${dayjs().format('DD_MMMM')}`}
      />
    </>
  );
};

export default NewJoineeList;
