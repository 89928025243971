import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  GET_BASIC_INFO: (employee_uid: string) => `/employee/${employee_uid}/family-details`,
  PUT_FAMILY_DETAILS: (employee_uid: string) => `/employee/${employee_uid}/family-details`,
  GET_ALL_DEPENDENTS: (employee_uid: string) => `/employee/${employee_uid}/family-detail`,
});

const getEmployeeDetails = async (employee_uid?: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_BASIC_INFO(employee_uid || ''));
  return response.data;
};

const updateFamilyDetails = async (payload: any, employee_uid?: string): Promise<any> => {
  const response = await axiosInstance.put(PATHS.PUT_FAMILY_DETAILS(employee_uid || ''), payload);
  return response;
};

const getAllEmpDependents = async (employee_uid?: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_ALL_DEPENDENTS(employee_uid || ''));
  return response;
};

const getSelfDependents = async (selfDetails: boolean, employee_uid?: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_ALL_DEPENDENTS(employee_uid || ''), {
    params: { only_self_details: selfDetails },
  });
  return response;
};

export interface FamilyAPIClient {
  readonly getEmployeeDetails: (employee_uid?: string) => Promise<any>;
  readonly updateFamilyDetails: (payload: any, employee_uid?: string) => Promise<any>;
  readonly getAllEmpDependents: (employee_uid?: string) => Promise<any>;
  readonly getSelfDependents: (selfDetails: boolean, employee_uid?: string) => Promise<any>;
}

const familyDetailsClient: FamilyAPIClient = Object.freeze({
  getEmployeeDetails,
  updateFamilyDetails,
  getAllEmpDependents,
  getSelfDependents,
});

export default familyDetailsClient;
