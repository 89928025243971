import { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip } from '@mui/material';
import Datatable from '../../components/Datatable/Datatable';
import { generateRows } from '../../components/Datatable/datatableUtils';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import DocumentClient from '../../api/Document/DocumentAPI';
import {
  candidateUid,
  dynamicColumns,
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
  initialSort,
  tableRowTotalField,
} from './InternsListConfig';

const InternsList: React.FC = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const { showSnackbar } = useDisplaySnackbar();
  const [isLoading, setLoading] = useState(false);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);

  const rowActions = [
    {
      label: 'Download',
      logo: (
        <Tooltip title="Download">
          <DownloadIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        const downloadLetterParams = {
          preSignedUrl: params.row.url,
        };
        DocumentClient.downloadDocument(downloadLetterParams)
          .then((res) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(
              new Blob([res.data], { type: 'application/pdf' }),
            );
            link.download = params.row.name;
            link.click();
          })
          .catch((e) => showSnackbar(e, 'error'));
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }, rowActions),
  );

  const getTableRows = (data: any) => {
    const formattedData = data.map((el: any, index: number) => ({ key: index, ...el }));
    return generateRows(formattedData, dynamicColumns, tableRowTotalField, candidateUid);
  };

  useEffect(() => {
    setLoading(true);
    DocumentClient.getInternList()
      .then((response: any) => {
        const rows = getTableRows(response.data);
        setTableRows(rows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <>
      <Datatable
        loading={isLoading}
        columns={getColumns(rowActions)}
        rows={tableRows}
        /* eslint-disable react/jsx-boolean-value */
        columnVisibility={true}
        initialSort={initialSort as any}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      />
    </>
  );
};

export default InternsList;
