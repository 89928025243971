import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Box, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import {
  getColumns,
  initialSort,
  candidateUid,
  initialShowCols,
  getInitialVisibilityModel,
} from './InterviewListTableConfig';
import { generateRows } from '../../components/Datatable/datatableUtils';
import InterviewClient, { PATHS as CandidatePaths } from '../../api/Interview/interviewAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { GetAllCandidateParams } from '../../api/Interview/interviewTypes';
import NoCandidate from '../../assets/icons/NoCandidate.png';
import useAllInterviewStyles from './AllInterviewStyles';
import MisDialog from '../../components/MisDialog/MisDialog';
import { ApiOperations } from '../../utils/utils';

interface InterviewListProps {
  readonly roundName: string;
  readonly selectedList: string;
  readonly isHRAccess: boolean;
  readonly selectedTab: string;
  readonly fetchCandidateList: number;
  readonly updateFetchCandidateList: any;
}

const InterviewList: React.FC<InterviewListProps> = ({
  roundName,
  selectedList,
  isHRAccess,
  selectedTab,
  fetchCandidateList,
  updateFetchCandidateList,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState('');
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useAllInterviewStyles();

  const rowActions: any = [
    {
      label: 'Delete',
      logo: (
        <Tooltip title="Delete">
          <DeleteIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteCandidateId(params.id);
      },
      path: CandidatePaths.ADD_CANDIDATE,
      operation: ApiOperations.DELETE,
    },
  ];

  if (roundName !== 'Applied') {
    rowActions.push({
      label: 'View Details',
      logo: (
        <Tooltip title="View Details">
          <ArrowOutwardIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        window.open(`/interview/details/${params.id}?selected_tab=${selectedTab - 1}`, '_blank');
      },
    });
  }

  const filteredRowActions = isHRAccess ? rowActions : [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel(
      { showColumns },
      selectedList,
      selectedTab,
      isHRAccess,
      filteredRowActions,
    ),
  );

  const [tableColumns, setTableColumns] = useState<any[]>(
    getColumns(selectedList, selectedTab, isHRAccess, filteredRowActions),
  );

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, [], [], candidateUid);
    return rows;
  };

  const generateDatatableInputs = () => {
    const getCandidateParams = {
      stage: roundName,
    };
    setIsLoading(true);
    InterviewClient.getAllCandidates(getCandidateParams)
      .then((response: GetAllCandidateParams[]) => {
        const rows = getTableRows(response);
        setTableRows(rows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    generateDatatableInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCandidateList]);

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteCandidateId) {
      setIsLoading(true);
      InterviewClient.deleteCandidate(deleteCandidateId)
        .then((result) => {
          updateFetchCandidateList();
          showSnackbar(result, 'success');
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteCandidateId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDeleteCandidateId('');
  };

  return (
    <>
      {tableRows.length === 0 && !isLoading ? (
        <Box>
          <Box className={styles.imageWrapper}>
            <Box
              component="img"
              src={NoCandidate}
              alt="No Interviews Found"
              className={styles.image}
            />
          </Box>
          <Box className={styles.messageWrapper}>
            <Box className={styles.message}>No interviews found</Box>
          </Box>
        </Box>
      ) : (
        <>
          <Datatable
            loading={isLoading}
            rows={tableRows}
            columns={tableColumns} /* eslint-disable react/jsx-boolean-value */
            columnVisibility={true}
            tableHeight="70vh"
            initialSort={initialSort as any}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            showExportButton
            exportedFileName={`Candidate_List_${dayjs().format('DD_MMMM')}`}
          />
          <MisDialog
            title={intl.formatMessage({
              id: I18nKey.INTERVIEW_MANAGEMENT_TITLE,
            })}
            message="Are you sure you want to delete this Candidate?"
            isOpen={openDialog}
            handleSuccess={handleDelete}
            handleClose={handleClose}
            actionBtnLabel="Delete"
          />
        </>
      )}
    </>
  );
};

export default InterviewList;
