import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  HARDWARES: '/hardware/assets/',
});

const getHardwareList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.HARDWARES}`);
  return response;
};

const HardwareClient: any = Object.freeze({
  getHardwareList,
});

export default HardwareClient;
