/* eslint-disable react/prop-types */
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import useAddFamilyStyles from './AddFamilyDetailsStyles';
import AllEmpDependents from './AllEmpDependents';
import I18nKey from '../../translations/I18nKey';

const ViewFamilyDetails: React.FC<any> = () => {
  const styles = useAddFamilyStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const handleViewMode = () => {
    navigate(`/myFamily/${id}?viewMode=${false}`);
  };
  return (
    <>
      <Box className={styles.viewFamilyDetailsWrapper}>
        <Box className={styles.viewDetailHeading}>
          <span>
            {intl.formatMessage({
              id: I18nKey.EMP_DEPENDENTS,
            })}
          </span>
        </Box>
        <Grid container rowSpacing={2} spacing={1}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <AllEmpDependents viewMode />
            <Button
              variant="contained"
              type="submit"
              className={styles.submitBtn}
              onClick={handleViewMode}>
              {intl.formatMessage({
                id: I18nKey.BUTTON_LABEL_UPDATE,
              })}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
    </>
  );
};

export default ViewFamilyDetails;
