import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import { assignedIntervieweesId, columns } from './AssigedIntervieweesListConfig';
import { GlobalContext } from '../../contexts/GlobalContext';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import Datatable from '../../components/Datatable/Datatable';
import MisDialog from '../../components/MisDialog/MisDialog';
import { ApiOperations } from '../../utils/utils';
import { PATHS } from '../../api/Opportunity/opportunityAPIs';
import { generateRows } from '../../components/Datatable/datatableUtils';
import useAllInterviewStyles from './AllInterviewStyles';
import I18nKey from '../../translations/I18nKey';

const AssignedIntervieweesList: React.FC = () => {
  const { checkAccess } = useContext(GlobalContext);
  const styles = useAllInterviewStyles();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const rowActions = [
    {
      label: 'Delete',
      logo: <DeleteIcon />,
      callback: (params: any) => {
        setOpenDialog(true);
        // setDeleteContactId(params.id);
      },
      path: PATHS.CRM_CONTACT,
      operation: ApiOperations.DELETE,
    },
  ];
  const tempJson = [
    {
      uid: '6',
      applicantName: 'Applicant Name 1',
      experience: 5,
      skill: 'java',
    },
    {
      uid: '4',
      applicantName: 'Applicant Name 2',
      experience: 2,
      skill: 'python',
    },
  ];
  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, [], [], assignedIntervieweesId);
    return rows;
  };

  const generateDatatableInputs = () => {
    const rows = getTableRows(tempJson);
    setTableRows(rows);
  };

  const handleDelete = () => {
    setOpenDialog(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
    // setDeleteContactId('');
  };
  useEffect(() => {
    generateDatatableInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box className={styles.tableWrapper}>
        <Datatable
          loading={isLoading}
          rows={tableRows}
          columns={columns(
            rowActions.filter(({ path, operation }) => checkAccess(path, operation)),
          )} /* eslint-disable react/jsx-boolean-value */
          hideFooter
          showFilterButton={false}
          showSearch={false}
          tableHeight={'40vh'}
        />
      </Box>
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.INTERVIEW_MANAGEMENT_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.APPLICANT_MESSAGE,
        })}
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel="Delete"
      />
    </>
  );
};

export default AssignedIntervieweesList;
