/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { Link, createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import I18nKey from '../../translations/I18nKey';
import useAllAppraisalsStyles from './AllAppraisalStyles';
import { appraisalId, getColumns, initialSort } from './AppraisalListConfig';
import { generateRows } from '../../components/Datatable/datatableUtils';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { GlobalContext } from '../../contexts/GlobalContext';
import AppraisalClient, { PATHS as AppraisalPaths } from '../../api/Appraisal/appraisalAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import {
  ApiOperations,
  getAppraisalFY,
  UserGroupRoles,
  getFYDateRange,
  hasSuperUserAccess,
} from '../../utils/utils';
import FYSelector from '../../components/FYSelector/FYSelector';
import MisDialog from '../../components/MisDialog/MisDialog';

const AllAppraisals = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingReportees, setIsLoadingReportees] = useState(false);
  const [isManagerAccess, setIsManagerAccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteAppraisalId, setDeleteAppraisalId] = useState('');
  const [fetch, setFetch] = useState<number>(0);
  const location = useLocation();

  const queryParams = queryString.parse(location?.search);
  const navigate = useNavigate();
  const currFYDateRange = getAppraisalFY(dayjs());
  const [currentFy, setCurrentFy] = useState(
    queryParams.startDate
      ? { startDate: queryParams.startDate, endDate: queryParams.endDate }
      : currFYDateRange,
  );

  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const styles = useAllAppraisalsStyles();
  const { employeeUid, groupNames } = useContext(GlobalContext);
  const { checkAccess } = useContext(GlobalContext);
  const [isdeleteAccess, setIsDeleteAccess] = useState<boolean>(
    hasSuperUserAccess(groupNames) || groupNames.includes(UserGroupRoles.HR),
  );

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);
  const rowActions = [
    {
      label: 'Delete',
      logo: <DeleteIcon />,
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteAppraisalId(params.id);
      },
      path: AppraisalPaths.GET_APPRAISAL,
      operation: ApiOperations.DELETE,
    },
  ];

  const handleYearChange = (dateRange: any) => {
    setCurrentFy({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
    navigate({
      search: createSearchParams({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }).toString(),
    });
  };

  const getTableRows = (data: any) => {
    const formattedData = data.map((el: any, index: number) => ({ key: index, ...el }));
    return generateRows(formattedData, [], [], appraisalId);
  };
  const updatedAppraisals = (appraisals: any) => {
    return appraisals.map((appraisal: any) => ({
      ...appraisal,
      projects: Array.isArray(appraisal.projects)
        ? appraisal.projects.map((project: any) => project.projectName).join(', ')
        : '',
    }));
  };

  const generateDatatableInputs = () => {
    const getAppraisalParams: any = {
      start_date: currentFy.startDate,
      end_date: currentFy.endDate,
    };
    setIsLoading(true);
    AppraisalClient.getAllAppraisals(getAppraisalParams)
      .then((response: any) => {
        const updatedResponse = updatedAppraisals(response);
        const rows = getTableRows(updatedResponse);
        setTableRows(rows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const getReportees = () => {
    setIsLoadingReportees(true);
    AppraisalClient.getAllReportees(employeeUid)
      .then((response: any) => {
        setIsManagerAccess(hasSuperUserAccess(groupNames) || response.length > 0);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoadingReportees(false));
  };

  useEffect(() => {
    generateDatatableInputs();
  }, [currentFy, fetch]);

  useEffect(() => {
    getReportees();
  }, [currentFy]);

  const handleClose = () => {
    setOpenDialog(false);
    setDeleteAppraisalId('');
  };

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteAppraisalId) {
      setIsLoading(true);
      AppraisalClient.deleteAppraisal(deleteAppraisalId)
        .then((result: any) => {
          updateFetch();
          showSnackbar(result, 'success');
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteAppraisalId('');
  };
  const handleRowDataChange = (updatedRows: any[]) => {
    setIsLoading(true);
    const updatedRemarks = updatedRows.map((r) => {
      return { uid: r.row.id, remarks: r.row.remarks };
    });

    AppraisalClient.updateAppraisalRemarks(updatedRemarks)
      .then((result: any) => {
        updateFetch();
        showSnackbar(result, 'success');
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };
  return (
    <Box className={styles.appraisalWrapper}>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.Header}>
        <span className="headerTitle">
          {intl.formatMessage({
            id: I18nKey.APPRAISAL_TITLE,
          })}
        </span>
        <Box className={isManagerAccess ? 'headerContent' : ''}>
          <FYSelector onChange={handleYearChange} defaultValue={currentFy} />
        </Box>
        {isManagerAccess && (
          <Link
            to={`/appraisal/startAppraisal?startDate=${currentFy.startDate}&endDate=${currentFy.endDate}`}>
            <Button className={styles.templateBtn} variant="contained">
              {intl.formatMessage({
                id: I18nKey.APPRAISAL_START_APPRAISAL,
              })}
            </Button>
          </Link>
        )}
      </Box>
      {!isLoading && !isLoadingReportees && (
        <Box className={styles.ListWrapper}>
          {tableRows.length > 0 ? (
            <>
              <Datatable
                loading={isLoading}
                columns={getColumns(
                  currentFy?.startDate,
                  currentFy?.endDate,
                  rowActions.filter(
                    ({ path, operation }) => checkAccess(path, operation) && isdeleteAccess,
                  ),
                )}
                // Allow edit only to Managers
                editable={isManagerAccess}
                rows={tableRows}
                /* eslint-disable react/jsx-boolean-value */
                columnVisibility={true}
                initialSort={initialSort as any}
                showExportButton
                exportedFileName={`Appraisal_List_${dayjs().format('DD_MMMM')}`}
                updateRows={handleRowDataChange}
              />
              <MisDialog
                title={intl.formatMessage({
                  id: I18nKey.APPRAISAL_TITLE,
                })}
                message={intl.formatMessage({
                  id: I18nKey.APPRAISAL_DELETE_CONFIRMATION,
                })}
                isOpen={openDialog}
                handleSuccess={handleDelete}
                handleClose={handleClose}
                actionBtnLabel={intl.formatMessage({
                  id: I18nKey.BUTTON_LABEL_REMOVE,
                })}
              />
            </>
          ) : (
            <Typography textAlign="center" padding={2} fontWeight="bold" color={'#173F74'}>
              Appraisal has not started
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
export default AllAppraisals;
