import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllInvoicesStyles = makeStyles((theme: Theme) => {
  return {
    invoiceWrapper: {
      padding: '1rem',
    },
    allInvoicesWrapper: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      position: 'relative',

      '& .header': {
        fontSize: '24px',
        fontWeight: '600',
        lineHeight: '27px',
        color: '#2e2e2e',
      },
      '& .datatable-custom-header': {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        'div:first-child': {
          minWidth: 300,
        },
      },
      '& .form-control': {
        position: 'relative',
        top: '-6px',
        right: '-5px',
        zIndex: 1,
        '& .select-month': {
          minWidth: 200,
          marginRight: '10px',
        },
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .downloadAllBtn': {
        position: 'absolute',
        right: '26.7rem',
        top: 7,
        zIndex: 2,
      },
    },
    Header: {
      fontSize: '14px',
      fontWeight: '600',
      paddingBottom: '10px',
      display: 'flex',
      justifyContent: 'space-between',

      '& .headerTitle': {
        fontSize: '24px',
      },
    },
    monthSelectorWrapper: {
      paddingTop: 5,

      '& .monthSelector': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiFormLabel-root': {
        paddingLeft: 4,
      },
    },
    formWrapper: {
      '& .upload-button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
    fabContainer: {
      position: 'fixed',
      bottom: '32px',
      right: '15px',
    },
    iconbutton: {
      bottom: '50px',
      right: '50px',
      zIndex: 100,
      position: 'absolute',
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',

      '& svg': {
        WebkitTransition: '-webkit-transform 300ms',
        transition: 'transform 300ms',
        WebkitTransform: `rotate(0deg)`,
        transform: `rotate(0deg)`,
        fontSize: '35px',
      },
    },
    inverted: {
      '& svg': {
        WebkitTransition: '-webkit-transform 300ms',
        transition: 'transform 300ms',
        WebkitTransform: `rotate(135deg)`,
        transform: `rotate(135deg)`,
      },
    },
    formField: {
      '& input': {
        height: '18px',
      },
    },
    confirmShareInfoWrapper: {
      marginTop: '-20px',
      fontSize: '14px',
      border: '1px solid #dddddd',
      borderRadius: '8px',
      padding: '10px',
    },
    infoshareWrapper: {
      display: 'flex',
      marginBottom: '10px',
    },
    infoShareText: {
      marginLeft: '10px',
    },
  };
});

export default useAllInvoicesStyles;
