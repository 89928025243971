import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import I18nKey from '../../translations/I18nKey';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import {
  generateRows,
  getInitialShowMonthsCols,
  getLastMonths,
  getShowMonthCols,
} from '../../components/Datatable/datatableUtils';
import {
  initialSort,
  dynamicColumns,
  revenueProjectIdName,
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
} from './revenueProjectTableConfig';
import RevenueClient from '../../api/Revenue/revenueAPIs';
import {
  DeleteProjectParams,
  GetRevenueParams,
  RevenueProject,
} from '../../api/Revenue/revenueTypes';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { getConvertedResult } from '../../utils/exchangeRates';
import MisDialog from '../../components/MisDialog/MisDialog';
import ProjectDeleteCloseForm from './forms/ProjectDeleteCloseForm';

interface RevenueListProps {
  readonly currentDateRange: any;
  readonly is_active?: boolean;
  readonly employee_id?: string;
  readonly isEditable?: boolean;
}
const RevenueList: React.FC<RevenueListProps> = ({
  currentDateRange,
  employee_id,
  isEditable,
  is_active,
}) => {
  const intl = useIntl();
  const [openDialog, setOpenDialog] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState('');
  const [fetch, setFetch] = useState(0);
  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);
  const initialRowActions = [
    {
      label: 'Close',
      logo: <LockIcon />,
      callback: (params: any) => {
        setOpenCloseDialog(true);
        setDeleteProjectId(params.id);
      },
    },
    {
      label: 'Delete',
      logo: <DeleteIcon />,
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteProjectId(params.id);
      },
    },
  ];
  const { showSnackbar } = useDisplaySnackbar();
  const [rowActions, setRowActions] = useState<any>(initialRowActions);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>({});
  const [tableColumns, setTableColumns] = useState<any[]>(
    getColumns({ currentDateRange }, rowActions),
  );
  const [showColumns, setShowColumns] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, dynamicColumns, [], revenueProjectIdName);
    return rows;
  };
  const [deleteInfo, setDeleteInfo] = useState<DeleteProjectParams>({
    endDate: dayjs().format('YYYY-MM-DD'),
    comment: '',
  });

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteProjectId) {
      setIsLoading(true);
      const { endDate, ...deleteProjectInfo } = deleteInfo;
      RevenueClient.deleteProjectInfo(deleteProjectId, deleteProjectInfo)
        .then((result) => {
          updateFetch();
          const response = result.data;
          if (response?.detail) {
            showSnackbar(result, 'success');
          }
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteProjectId('');
  };

  const handleCloseOpportunity = () => {
    setOpenCloseDialog(false);
    if (deleteProjectId) {
      setIsLoading(true);
      const closeInfo = { project: { ...deleteInfo, isActive: false }, project_finance: [] };
      RevenueClient.updateProjectInfo(deleteProjectId, closeInfo)
        .then((result) => {
          updateFetch();
          const response = result.data;
          if (response?.detail) {
            showSnackbar(result, 'success');
          }
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteProjectId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenCloseDialog(false);
    setDeleteProjectId('');
  };

  useEffect(() => {
    const currentColums = [
      ...initialShowCols,
      ...getShowMonthCols(currentDateRange.startDate, currentDateRange.endDate),
    ];
    setColumnVisibilityModel(getInitialVisibilityModel({ ...currentDateRange }, currentColums));

    const columns = getColumns({ currentDateRange }, rowActions);
    setTableColumns(columns);
    setIsLoading(true);
    const getRevenueProjectParams: GetRevenueParams = {
      employee_uid: employee_id,
      start_date: currentDateRange.startDate,
      end_date: currentDateRange.endDate,
      is_active,
    };
    RevenueClient.getRevenueByProjectList(getRevenueProjectParams)
      .then((result) => {
        setIsLoading(false);
        const convertedCurrency = getConvertedResult(result, 'totalRevenue');
        const rows = getTableRows(convertedCurrency as RevenueProject[]);
        setTableRows(rows);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentDateRange, fetch]);

  useEffect(() => {
    setRowActions(is_active ? initialRowActions : initialRowActions.slice(1));
    if (rowActions.length) {
      const columns = getColumns({ currentDateRange, columnVisibilityModel }, rowActions);
      setTableColumns(columns);
      const updatedTableCols: any = [];
      Object.keys(columnVisibilityModel).forEach((col: string) => {
        if (columnVisibilityModel[col] !== false)
          updatedTableCols.push(dayjs(col).isValid() ? dayjs(col).month() : col);
      });
      setShowColumns(updatedTableCols);
    }
  }, [columnVisibilityModel, is_active]);

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <>
      <Datatable
        loading={isLoading}
        rows={tableRows}
        columns={tableColumns}
        initialSort={initialSort as any}
        tableHeight="62vh"
        /* eslint-disable react/jsx-boolean-value */
        editable={isEditable}
        showTotalRowFooter={true}
        columnVisibility={true}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        showExportButton
        exportedFileName={`Revenue_List_${dayjs().format('DD_MMMM')}`}
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.PROJECT_DETAIL_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.PORJECT_DETAIL_REMOVE_PROJECT,
        })}
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        disableSubmitBtn={!deleteInfo}
        actionBtnLabel="Delete"
        additionalInfoSection={
          <ProjectDeleteCloseForm setDeleteInfo={setDeleteInfo} isProjectCloseForm={false} />
        }
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.PROJECT_DETAIL_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.PORJECT_DETAIL_CLOSE_PROJECT,
        })}
        isOpen={openCloseDialog}
        handleSuccess={handleCloseOpportunity}
        handleClose={handleClose}
        disableSubmitBtn={!deleteInfo}
        actionBtnLabel="Close Project"
        additionalInfoSection={
          <ProjectDeleteCloseForm setDeleteInfo={setDeleteInfo} isProjectCloseForm={true} />
        }
      />
    </>
  );
};

RevenueList.defaultProps = {
  employee_id: '',
  isEditable: false,
  is_active: false,
};

export default RevenueList;
