import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import ResourceDetail from './ResourceDetail';
import ResourceList from './ResourceList';
import useAllResourcesStyles from './AllResourcesStyles';
import CustomerRelationClient from '../../api/Opportunity/opportunityAPIs';
import { getFullName } from '../Opportunity/entitiesFormConfig';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ResourceClient from '../../api/Resource/resourceAPIs';
import { GetResourceParams } from '../../api/Resource/resourceTypes';
import { dateFrom, dateTo } from './resourceListConfig';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

const AllResources = () => {
  const [selectedResource, setSelectedResource] = useState<any>();
  const [employees, setEmployees] = useState<any>([]);
  const [fetch, setFetch] = useState(0);
  const [resourceListData, setResourceListData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const styles = useAllResourcesStyles();
  const intl = useIntl();
  const params = useParams();
  const { id } = params;
  const { showSnackbar } = useDisplaySnackbar();
  const navigate = useNavigate();

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);

  const handleSuccessfulSubmit = () => {
    setSelectedResource(null);
    updateFetch();
  };

  const handleClose = () => {
    navigate('/resource');
    setSelectedResource(null);
  };

  const getAllEmployee = () => {
    CustomerRelationClient.getEmployeeList()
      .then((data) => {
        setEmployees(
          data.map((contact: any) => ({ label: getFullName(contact), value: contact.uid })),
        );
      })
      .catch((e) => showSnackbar(e, 'error'));
  };

  const getResourceList = () => {
    setIsLoading(true);
    const getResourceParams: GetResourceParams = {
      // TODO: API integration, check to make single component with paramerized status
      start_date: dateFrom,
      end_date: dateTo,
    };
    ResourceClient.getResourceList(getResourceParams)
      .then((data) => {
        setResourceListData(data);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getAllEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getResourceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  useEffect(() => {
    if (id && resourceListData) {
      setSelectedResource(resourceListData.find((emp: any) => emp.employeeUid === id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, resourceListData]);

  return (
    <Box className={styles.resourceWrapper}>
      <Box className={styles.headerWrapper}>
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.RESOURCE_TITLE,
            })}
          </span>
        </Box>
        <Box>
          <Button
            className={styles.templateBtn}
            variant="contained"
            onClick={() => navigate('/resource/viewBySkill')}>
            {intl.formatMessage({
              id: I18nKey.RESOURCE_LIST_BY_SKILL,
            })}
          </Button>
          <Button
            className={styles.templateBtn}
            variant="contained"
            onClick={() => navigate('/resource/viewByProject')}>
            {intl.formatMessage({
              id: I18nKey.RESOURCE_LIST_BY_PROJECT,
            })}
          </Button>
        </Box>
      </Box>

      <Box className={styles.contentWrapper}>
        <Box className={params.id ? `${styles.ListWrapper} ${styles.w70}` : styles.ListWrapper}>
          <ResourceList
            resourceListData={resourceListData}
            updateFetch={updateFetch}
            apiLoading={isLoading}
          />
        </Box>

        {selectedResource && (
          <ResourceDetail
            selectedResource={selectedResource}
            employees={employees}
            handleClose={handleClose}
            handleSuccessfulSubmit={handleSuccessfulSubmit}
            updateFetch={updateFetch}
          />
        )}
      </Box>
    </Box>
  );
};

export default AllResources;
