import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import Datatable from '../../components/Datatable/Datatable';
import { F4 } from '../../utils/oppStages';
import AddEntities from './AddEntities';
import { getColumns, initialSort } from './opportunityTableConfig';
import MisDialog from '../../components/MisDialog/MisDialog';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import OpportunityDeleteInfo from './OpportunityDeleteInfo';
import {
  CloseOpportunityParams,
  DeleteOpportunityParams,
} from '../../api/Opportunity/opportunityTypes';
import CustomerRelationClient from '../../api/Opportunity/opportunityAPIs';

// TODO: Check while API integration to make single component for Active and Completed

interface CustomerRelationsProps {
  readonly stage?: string | null;
  readonly isEditable?: boolean;
  readonly rows: any[];
  readonly onCreateOpportunity?: Function;
  readonly apiLoading: boolean;
}

const CustomerRelations: React.FC<CustomerRelationsProps> = ({
  stage,
  isEditable,
  rows,
  onCreateOpportunity,
  apiLoading,
}: any) => {
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [deleteOpportunityId, setDeleteOpportunityId] = useState('');
  const [deleteInfo, setDeleteInfo] = useState<DeleteOpportunityParams>({
    comment: '',
  });

  const rowActions = [
    {
      label: 'Close',
      logo: <LockIcon />,
      callback: (params: any) => {
        setOpenCloseDialog(true);
        setDeleteOpportunityId(params.id);
      },
    },
    {
      label: 'Delete',
      logo: <DeleteIcon />,
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteOpportunityId(params.id);
      },
    },
  ];

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteOpportunityId) {
      setIsLoading(true);
      CustomerRelationClient.deleteOpportunity(deleteOpportunityId, deleteInfo)
        .then((result) => {
          onCreateOpportunity();
          const response = result.data;
          if (response?.detail) {
            showSnackbar(result, 'success');
          }
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteOpportunityId('');
  };

  const handleCloseOpportunity = () => {
    setOpenCloseDialog(false);
    if (deleteOpportunityId) {
      setIsLoading(true);
      const closeInfo: CloseOpportunityParams = { ...deleteInfo, isActive: false };
      CustomerRelationClient.updateCRMOpportunity(deleteOpportunityId, closeInfo)
        .then((result) => {
          onCreateOpportunity();
          const response = result.data;
          if (response?.detail) {
            showSnackbar(result, 'success');
          }
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteOpportunityId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenCloseDialog(false);
    setDeleteOpportunityId('');
  };

  return (
    <>
      <Datatable
        loading={isLoading || apiLoading}
        rows={rows}
        columns={getColumns({ hideStage: !!stage, rowActions })}
        columnVisibility={false}
        tableHeight="70vh"
        initialSort={initialSort as any}
        /* eslint-disable react/jsx-boolean-value */
        editable={isEditable}
        showExportButton
        exportedFileName={`Opportunity_List_${dayjs().format('DD_MMMM')}`}
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.CUSTOMER_RELATION_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.CUSTOMER_RELATION_REMOVE_OPPORTUNITY,
        })}
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel="Delete"
        additionalInfoSection={<OpportunityDeleteInfo setDeleteInfo={setDeleteInfo} />}
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.CUSTOMER_RELATION_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.CUSTOMER_RELATION_CLOSE_OPPORTUNITY,
        })}
        isOpen={openCloseDialog}
        handleSuccess={handleCloseOpportunity}
        handleClose={handleClose}
        actionBtnLabel="Close Opportunity"
        additionalInfoSection={<OpportunityDeleteInfo setDeleteInfo={setDeleteInfo} />}
      />
      {stage === F4 || !stage ? <AddEntities onCreateOpportunity={onCreateOpportunity!} /> : null}
    </>
  );
};

CustomerRelations.defaultProps = {
  isEditable: false,
  onCreateOpportunity: () => {},
  stage: null,
};
export default CustomerRelations;
