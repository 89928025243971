import { Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { replaceEmpty } from '../../utils/utils';

const initialSort = { field: 'username', sort: 'asc' };

const renderCellTooltip = (value: string, length: number = 10) => {
  const newVal = replaceEmpty(value);

  return newVal?.length > length ? (
    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{newVal}</span>} arrow>
      <div className="MuiDataGrid-cell--textLeft">{newVal}</div>
    </Tooltip>
  ) : (
    <div className="MuiDataGrid-cell--textLeft">{newVal}</div>
  );
};
const columns = [
  {
    field: 'username',
    type: 'string',
    headerName: 'Username',
    flex: 1.5,
    sortable: true,
    renderCell: ({ value }: GridRenderCellParams) => {
      return <span>{renderCellTooltip(`${value}`, 20)}</span>;
    },
  },
  {
    field: 'operatingSystem',
    type: 'string',
    headerName: 'Operating System',
    flex: 1.5,
    sortable: true,
    renderCell: ({ value }: GridRenderCellParams) => {
      return <span>{renderCellTooltip(`${value}`, 20)}</span>;
    },
  },
  {
    field: 'osVersion',
    type: 'string',
    headerName: 'OS Version',
    flex: 1.5,
    sortable: true,
  },
  {
    field: 'make',
    type: 'string',
    headerName: 'Brand',
    flex: 1,
    sortable: true,
    hide: true,
    hideable: true,
  },
  {
    field: 'model',
    type: 'string',
    headerName: 'Model',
    flex: 1,
    sortable: true,
  },
  {
    field: 'ram',
    type: 'string',
    headerName: 'RAM',
    flex: 1,
    sortable: true,
    renderCell: (params: any) => <span>{params.value} GB</span>,
  },
  {
    field: 'storageSpace',
    type: 'string',
    headerName: 'Storage Space',
    flex: 1,
    sortable: true,
  },
  {
    field: 'processor',
    type: 'string',
    headerName: 'Processor',
    flex: 2,
    renderCell: ({ value }: GridRenderCellParams) => {
      return <span>{renderCellTooltip(`${value}`, 25)}</span>;
    },

    sortable: true,
  },
  {
    field: 'macAddress',
    type: 'string',
    headerName: 'MAC Address',
    flex: 1.5,
    sortable: true,
    hide: true,
    hideable: true,
  },
];
const getInitialVisibilityModel = ({ showColumns }: any) => {
  const initialVisibilityModelObj: any = {};
  columns.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });

  return initialVisibilityModelObj;
};

const initialShowCols = [
  'username',
  'operatingSystem',
  'osVersion',
  'model',
  'ram',
  'storageSpace',
  'processor',
];
const hardwareIdName = 'assetId';
export { columns, initialSort, hardwareIdName, initialShowCols, getInitialVisibilityModel };
