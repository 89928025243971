import axiosInstance from '../axiosInstance';
import { Vacancy } from './vacanciesTypes';

export const PATHS = Object.freeze({
  VACANCIES: '/vacancy/',
  PUT_VACANCY: (vacancy_uid: any) => `/vacancy/${vacancy_uid}`,
  ADD_VACANCY: '/vacancy/',
  GET_CUSTOMERS: 'vacancy/customers/listing',
  GET_PROJECTS: 'vacancy/projects/listing',
  DELETE_VACANCY: (vacancy_uid: any) => `/vacancy/${vacancy_uid}`,
  UPDATE_REMARKS: '/vacancy/update/remarks',
  CLONE_VACANCY: (vacancy_uid: any) => `/vacancy/${vacancy_uid}/clone`,
  VACANCY: (vacancy_uid: any) => `/vacancy/${vacancy_uid}`,
});

const getVacanciesList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.VACANCIES}`);
  return response;
};
const getVacancy = async (vacancy_uid: string): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.VACANCY(vacancy_uid)}`);
  return response;
};
const getProjects = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_PROJECTS}`);
  return response;
};

const getCustomers = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_CUSTOMERS}`);
  return response;
};
const addVacancies = async (addVacanciesPayload: any): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.ADD_VACANCY}`, addVacanciesPayload);
  return response;
};
const updateVacanciesDetails = async (
  vacancy_uid: string,
  updateVacanciesPayload: any,
): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.PUT_VACANCY(vacancy_uid)}`,
    updateVacanciesPayload,
  );
  return response;
};

const deleteVacancy = async (vacancy_uid: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.DELETE_VACANCY(vacancy_uid)}`);
  return response;
};

const updateBulkRemarks = async (updatedRemarks: any): Promise<any> => {
  const response = await axiosInstance.put(`${PATHS.UPDATE_REMARKS}`, updatedRemarks);
  return response;
};
const cloneVacanciesDetails = async (vacancy_uid: string): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.CLONE_VACANCY(vacancy_uid)}`);
  return response;
};
const VacanciesClient: any = Object.freeze({
  getVacanciesList,
  updateVacanciesDetails,
  getProjects,
  getCustomers,
  addVacancies,
  deleteVacancy,
  updateBulkRemarks,
  cloneVacanciesDetails,
  getVacancy,
});

export default VacanciesClient;
