/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import {
  dynamicColumns,
  employeeUid,
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
  initialSort,
  tableRowTotalField,
} from './EmployeeListConfig';
import FYSelector from '../../components/FYSelector/FYSelector';
import DocumentClient from '../../api/Document/DocumentAPI';
import { generateRows } from '../../components/Datatable/datatableUtils';
import useDocumentStyles from './DocumentStyles';
import { getAppraisalFY, getMinimumValueForFY } from './utils';

const AllEmployeesList: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableRows, setTableRows] = useState<any[]>([]);
  const { showSnackbar } = useDisplaySnackbar();
  const [noOfLettersShared, setNoOfLettersShared] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const styles = useDocumentStyles();
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }),
  );
  const { financialYearEnd, financialYearStart } = getAppraisalFY(2024, 2025);
  const queryParams = new URLSearchParams(location.search);
  const startDateParam = queryParams.get('startDate') || financialYearStart;
  const endDateParam = queryParams.get('endDate') || financialYearEnd;

  const [currentFy, setCurrentFy] = useState({
    startDate: startDateParam,
    endDate: endDateParam,
  });

  const getAllAppraisals = (startDate?: any, endDate?: any) => {
    const getCompensationParams: any = {
      start_date: startDate || currentFy.startDate,
      end_date: endDate || currentFy.endDate,
    };
    setLoading(true);
    DocumentClient.getAllCompensationList(getCompensationParams)
      .then((response: any) => {
        const rows = generateRows(response, dynamicColumns, tableRowTotalField, employeeUid);
        const sharedLetterRows = rows.filter((v) => v.isShared === 'Yes');
        setNoOfLettersShared(sharedLetterRows.length);
        setTableRows(rows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllAppraisals();
  }, [currentFy]);

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  const handleYearChange = (dateRange: any) => {
    const startYear = parseInt(dateRange.startDate.split('-')[0], 10);
    const endYear = parseInt(dateRange.endDate.split('-')[0], 10);
    const response = getAppraisalFY(startYear, endYear);
    const startDate = response.financialYearStart;
    const endDate = response.financialYearEnd;
    navigate({
      search: `?startDate=${startDate}&endDate=${endDate}`,
    });

    getAllAppraisals(startDate, endDate);
    setCurrentFy(dateRange);
  };

  const transformDefaultValue = () => ({
    startDate: startDateParam,
    endDate: endDateParam,
  });

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mr={0.5}>
        <FYSelector
          onChange={handleYearChange}
          minValue={getMinimumValueForFY()}
          maxValue={getAppraisalFY(2024, 2025)}
          defaultValue={transformDefaultValue()}
        />
      </Box>
      <Box className={styles.allContentWrapper}>
        <Typography className="sharedInfoText">Letters Shared: {noOfLettersShared}</Typography>
        <Datatable
          loading={isLoading}
          columns={getColumns(currentFy)}
          rows={tableRows}
          columnVisibility
          initialSort={initialSort as any}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          showExportButton
          exportedFileName={`Employee_List_${dayjs().format('DD_MMMM')}`}
        />
      </Box>
    </>
  );
};

export default AllEmployeesList;
