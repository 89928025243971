import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useWorkspaceStyles = makeStyles((theme: Theme) => {
  return {
    itemWrapper: {
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    routeItem: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderRadius: 12,
      minHeight: 100,
      border: '1px solid #EBEBEB',
      transition: 'background-color 0.5s ease, box-shadow 0.5s ease, transform 0.2s ease',
      '&:hover': {
        backgroundColor: theme.palette.secondary.lighter,
        boxShadow: '1px 1px 2px #D2D2D2',
        transform: 'scale(1.03)',
      },
      '& p': {
        fontSize: '14px !important',
      },
    },
    ModuleIcon: {
      width: '50px',
      height: '50px',
    },
    header: {
      marginBottom: '18px !important',
      fontSize: '18px !important',
    },
  };
});

export default useWorkspaceStyles;
