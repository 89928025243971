import * as Yup from 'yup';

const addFamilyDetialsFormValidation = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().required('Last Name is required'),
  gender: Yup.object()
    .shape({ label: Yup.string(), code: Yup.string() })
    .typeError('Gender is required'),
  dob: Yup.string().required('Date of Birth is required'),
  isMarried: Yup.boolean().nullable(),
  spouseFirstName: Yup.string().when('isMarried', {
    is: true,
    then: Yup.string().required('First Name is required').typeError('First Name is required'),
    otherwise: Yup.string().nullable(),
  }),
  spouseMiddleName: Yup.string().nullable(),
  spouseLastName: Yup.string().when('isMarried', {
    is: true,
    then: Yup.string().required('Last Name is required').typeError('Last Name is required'),
    otherwise: Yup.string().nullable(),
  }),
  spouseRelation: Yup.object().when('isMarried', {
    is: true,
    then: Yup.object()
      .shape({ label: Yup.string(), value: Yup.string() })
      .typeError('Relation is required'),
    otherwise: Yup.object().nullable(),
  }),
  spouseDob: Yup.string().when('isMarried', {
    is: true,
    then: Yup.string().required('Date of Birth is required').typeError('Date of Birth is required'),
    otherwise: Yup.string().nullable(),
  }),
  childrenData: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      middleName: Yup.string().nullable(),
      lastName: Yup.string().required('Last Name is required'),
      relation: Yup.object()
        .shape({ label: Yup.string(), code: Yup.string() })
        .typeError('Relation is required'),
      dob: Yup.string().required('Date of Birth is required'),
    }),
  ),
  parentsData: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      middleName: Yup.string().nullable(),
      lastName: Yup.string().required('Last Name is required'),
      dob: Yup.string().required('Date of Birth is required'),
      relation: Yup.object()
        .shape({ label: Yup.string(), code: Yup.string() })
        .typeError('Relation is required'),
    }),
  ),
});

const gender = [
  {
    label: 'Male',
    value: 'male',
  },
  { label: 'Female', value: 'female' },
];
const spouseRelation = [
  {
    label: 'Husband',
    value: 'husband',
  },
  { label: 'Wife', value: 'wife' },
];
const childRelation = [
  {
    label: 'Daughter',
    code: 'daughter',
  },
  { label: 'Son', code: 'son' },
];
const parentRelation = [
  {
    label: 'Father',
    code: 'father',
  },
  { label: 'Mother', code: 'mother' },
];

const getUpdatedInfo = (data: any) =>
  data.map((v: any) => {
    const { uid, ...rest } = v;
    return uid !== undefined ? { ...rest, uid } : rest;
  });

const spouseGenderMapping = {
  Male: 'Husband',
  Female: 'Wife',
};

export {
  addFamilyDetialsFormValidation,
  getUpdatedInfo,
  gender,
  childRelation,
  spouseRelation,
  parentRelation,
  spouseGenderMapping,
};
