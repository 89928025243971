import { createTheme, PaletteColorOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary?: PaletteColor;
    appBackground?: PaletteColor;
    popUpBackground?: PaletteColor;
    status?: PaletteColor;
    shadow?: PaletteColor;
  }
  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
    appBackground?: PaletteColorOptions;
    popUpBackground?: PaletteColorOptions;
    status?: PaletteColorOptions;
    shadow?: PaletteColorOptions;
  }

  interface TypeText {
    lightest?: string;
    light?: string;
    midDark?: string;
    darkest?: string;
  }

  interface PaletteColor {
    lightest?: string;
    lighter?: string;
    midDark?: string;
    darker?: string;
    darkest?: string;
    disabled?: string;
    required?: string;
    danger?: string;
    success?: string;
    warning?: string;
  }

  interface SimplePaletteColorOptions {
    lightest?: string;
    lighter?: string;
    midDark?: string;
    darker?: string;
    darkest?: string;
    disabled?: string;
    required?: string;
    danger?: string;
    success?: string;
    warning?: string;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      // White
      main: '#FFFFFF',
      disabled: 'rgba(0, 0, 0, 0.12) ',
    },
    secondary: {
      // Blue
      lighter: '#EBF6FF',
      light: '#0276D5',
      midDark: '#BFE8FF',
      main: '#094A9E',
    },
    tertiary: {
      // Gray
      light: '#757575',
      main: '#D9D9D9',
    },
    appBackground: {
      lightest: '#FFFFFF',
      main: '#F6F6F6',
      darkest: '#000000',
    },
    popUpBackground: {
      lightest: '#FFFFFF',
      light: '#FFF1E8', // light pink
      lighter: '#FAFBFF',
      main: '#F2F5FF',
    },
    text: {
      lightest: '#FFFFFF',
      light: '#39A5F5',
      midDark: '#173F74',
      darkest: '#000000',
      disabled: '#757575',
    },
    status: {
      main: '',
      required: 'red',
      danger: '#C62828',
      midDark: '#2E7D32',
      success: '#088208a3',
      warning: '#FF8C05',
    },
    shadow: {
      main: 'rgba(0, 0, 0, 0.25)',
    },
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
  },
});

export default theme;
