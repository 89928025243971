import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import LinkedInClient from '../../api/LinkedInContacts/linkedInAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import Datatable from '../../components/Datatable/Datatable';
import {
  contactId,
  dynamicColumns,
  tableRowTotalField,
  columns,
  initialShowCols,
  getInitialVisibilityModel,
} from './linkedInTableConfig';
import useAllLinkedInStyles from './AllLinkedInStyles';
import I18nKey from '../../translations/I18nKey';
import { generateRows } from '../../components/Datatable/datatableUtils';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import LinkedInDataFilter from './LinkedInDataFilter';

const AllLinkedInContacts = () => {
  const [tableColumns, setTableColumns] = useState<any[]>(columns);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any | null>(null);
  const styles = useAllLinkedInStyles();
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }),
  );
  const [fetch, setFetch] = useState<number>(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    limit: number;
  }>({
    page: 0,
    limit: 100,
  });
  const [initialValues, setInitialValues] = useState({
    filters: [{ columnName: '', operatorName: '', values: [''] }],
  });
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const updateFetchLinkedInList = () => setFetch((fetchNum) => fetchNum + 1);

  const generateDatatableInputs = (filterdata: any | null = null) => {
    setIsLoading(true);
    LinkedInClient.getAllLinkedInList(paginationModel, filterdata)
      .then((response) => {
        const rows = generateRows(response.data, dynamicColumns, tableRowTotalField, contactId);
        setTableRows(rows);
        setTotalRows(response.totalRecords);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleRowDataChange = (updatedRows: any[]) => {
    setIsLoading(true);
    const updatedRemarks = updatedRows.map((r) => {
      return { uid: r.row.id, remarks: r.row.remarks, is_relevant: r.row.is_relevant };
    });
    LinkedInClient.updateLinkedInDetails(updatedRemarks)
      .then((result) => {
        showSnackbar(result, 'success');
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  useEffect(() => {
    generateDatatableInputs(filteredData);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [paginationModel, fetch]);

  useEffect(() => {
    setTableColumns(columns);
    const updatedTableCols: any = [];
    Object.keys(columnVisibilityModel).forEach((col: string) => {
      if (columnVisibilityModel[col] !== false) updatedTableCols.push(col);
    });

    setShowColumns(updatedTableCols);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [columnVisibilityModel]);

  const handleSubmit = (v: any) => {
    setInitialValues(v);
    const transformedData: any = {
      advanced_filters: {},
    };
    v.filters.forEach((filter: any) => {
      const columnName = filter.columnName.value;
      const operations = filter.operatorName.value;
      const { values } = filter;
      if (values.length > 0) {
        if (!transformedData.advanced_filters[columnName]) {
          transformedData.advanced_filters[columnName] = [];
        }
        const existingEntry = transformedData.advanced_filters[columnName]?.find(
          (entry: any) => entry.operation === operations,
        );
        if (existingEntry) {
          existingEntry.data = existingEntry.data.concat(values);
        } else {
          transformedData.advanced_filters[columnName].push({
            data: values,
            operation: operations,
          });
        }
      }
    });
    setFilteredData(transformedData);
    generateDatatableInputs(transformedData);
  };

  return (
    <>
      <Box className={styles.allLinkedInWrapper}>
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.LINKED_IN_TITLE,
            })}
          </span>
        </Box>
        <LinkedInDataFilter
          disabled={isLoading}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          updateFetchLinkedInList={updateFetchLinkedInList}
          setFilteredData={setFilteredData}
        />
        <Datatable
          loading={isLoading}
          rows={tableRows}
          columns={tableColumns}
          columnVisibility
          editable
          showPageNumber
          updateRows={handleRowDataChange}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          rowsPerPageOptions={[100]}
          paginationMode="server"
          rowCount={totalRows || 0}
          skipConfirmation
          tableHeight="76vh"
          showFilterButton={false}
        />
      </Box>
    </>
  );
};

export default AllLinkedInContacts;
