import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import {
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
  initialSort,
} from './accountListTableConfig';
import CustomerRelationClient, {
  PATHS as OpportunityPaths,
} from '../../api/Opportunity/opportunityAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import MisDialog from '../../components/MisDialog/MisDialog';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApiOperations } from '../../utils/utils';

interface AccountListProps {
  readonly tableRows: any[];
  readonly updateFetch: () => void;
  readonly handleCloseOnDelete: () => void;
  readonly apiLoading: boolean;
}

const AccountList: React.FC<AccountListProps> = ({
  tableRows,
  updateFetch,
  handleCloseOnDelete,
  apiLoading,
}) => {
  const { showSnackbar } = useDisplaySnackbar();
  const { id } = useParams();
  const { checkAccess } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteAccountId, setDeleteAccountId] = useState('');
  const [showColumns, setShowColumns] = useState([...initialShowCols]);

  const rowActions = [
    {
      label: 'Delete',
      logo: <DeleteIcon />,
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteAccountId(params.id);
      },
      path: OpportunityPaths.CRM_ACCOUNT,
      operation: ApiOperations.DELETE,
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel(
      { showColumns },
      rowActions.filter(({ path, operation }) => checkAccess(path, operation)),
    ),
  );

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteAccountId) {
      setIsLoading(true);
      CustomerRelationClient.deleteCRMAccount(deleteAccountId)
        .then((result: any) => {
          if (id && deleteAccountId === id) handleCloseOnDelete();
          if (updateFetch) updateFetch();
          showSnackbar(result, 'success');
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteAccountId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDeleteAccountId('');
  };

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <>
      <Datatable
        loading={apiLoading || isLoading}
        rows={tableRows}
        columns={getColumns(
          rowActions.filter(({ path, operation }) => checkAccess(path, operation)),
        )}
        /* eslint-disable react/jsx-boolean-value */
        columnVisibility={true}
        initialSort={initialSort as any}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        showExportButton
        exportedFileName={`Account_List_${dayjs().format('DD_MMMM')}`}
      />
      <MisDialog
        title="Accounts"
        message="Are you sure you want to delete this account?"
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel="Delete"
      />
    </>
  );
};

export default AccountList;
