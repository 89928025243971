/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MisPopover from '../MisPopover/MisPopover';
import SimpleAutocompleteField from '../SimpleAutocompleteField/SimpleAutocompleteField';
import SimpleInputField from '../SimpleInputField/SimpleInputField';
import I18nKey from '../../translations/I18nKey';
import useAllResourceListStyles from '../../pages/Resource/ResourceListStyle';
import { getColumnDataType, isBooleanColumn, onColumnChange, operatorOptions } from './FilterUtils';

const CustomizeFilter = ({
  initialValues,
  columnNameList,
  handleSubmit,
  style,
  handleClearAllFilter,
  disabled,
}: any) => {
  const [filterBox, setFilterBox] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(filterBox);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterBox(event.currentTarget);
  };

  const handleClose = () => {
    setFilterBox(null);
  };

  const intl = useIntl();
  const styles = useAllResourceListStyles();

  return (
    <Box>
      <Box>
        <Button onClick={handleClick} className={style} disabled={disabled}>
          <FilterListIcon color={disabled ? 'disabled' : 'primary'} />
          {intl.formatMessage({
            id: I18nKey.LINKED_IN_FILTER,
          })}
        </Button>
      </Box>
      <MisPopover open={open} anchorEl={filterBox} onClose={handleClose}>
        <Tooltip title={'Close'}>
          <CloseIcon onClick={handleClose} className={styles.closeIcon} />
        </Tooltip>
        <Box className={styles.linkedlnfilterFormWrapper}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
            {(formik) => {
              const { values, setFieldValue } = formik;

              return (
                <Form>
                  <FieldArray
                    name="filters"
                    render={({ push, remove }) => (
                      <>
                        <Box>
                          {formik.values.filters.map((filter: any, index: any) => {
                            const columnType = getColumnDataType(
                              filter.columnName.value,
                              columnNameList,
                            );

                            return (
                              <Grid container rowSpacing={2} spacing={1.5} key={index}>
                                <Grid item xs={0.5} className={styles.removeIconContainer}>
                                  {index > 0 && (
                                    <Tooltip
                                      title={
                                        values.filters[index - 1].columnName === filter.columnName
                                          ? 'OR'
                                          : 'And'
                                      }>
                                      <IconButton className={styles.AndIcon}>
                                        {values.filters[index - 1].columnName === filter.columnName
                                          ? '||'
                                          : '&&'}
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Grid>
                                <Grid item xs={0.9} className={styles.removeIconContainer}>
                                  {index > 0 && (
                                    <Tooltip title="Remove Filter">
                                      <IconButton onClick={() => remove(index)}>
                                        <ClearIcon className={styles.removeIcon} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Grid>
                                <Grid item xs={3}>
                                  <SimpleAutocompleteField
                                    name={`filters[${index}].columnName`}
                                    placeholder="Select column"
                                    size="small"
                                    variant="standard"
                                    setFieldValue={setFieldValue}
                                    options={columnNameList}
                                    value={values.filters[index].columnName}
                                    values={values}
                                    disableClearable
                                  />
                                </Grid>
                                <Grid item xs={2.2}>
                                  <SimpleAutocompleteField
                                    name={`filters[${index}].operatorName`}
                                    placeholder="Select operator"
                                    size="small"
                                    variant="standard"
                                    setFieldValue={setFieldValue}
                                    options={
                                      (values.filters[index].columnName &&
                                        onColumnChange(
                                          values.filters[index].columnName,
                                          columnNameList,
                                        )) ||
                                      operatorOptions
                                    }
                                    value={values?.filters[index].operatorName}
                                    values={values}
                                    disableClearable
                                  />
                                </Grid>
                                {columnType === 'date' ? (
                                  <Grid item xs={5}>
                                    {values.filters[index].operatorName.value === 'after' ||
                                    values.filters[index].operatorName.value === 'before' ? (
                                      <SimpleInputField
                                        label="Date"
                                        name={`filters[${index}].values[0]`}
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                          style: {
                                            fontSize: 13,
                                          },
                                        }}
                                        size="small"
                                        values={values}
                                      />
                                    ) : (
                                      <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                          <SimpleInputField
                                            label="Start Date"
                                            name={`filters[${index}].values[0]`}
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ style: { fontSize: 13 } }}
                                            size="small"
                                            fullWidth
                                            values={values}
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <SimpleInputField
                                            label="End Date"
                                            name={`filters[${index}].values[1]`}
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ style: { fontSize: 13 } }}
                                            size="small"
                                            fullWidth
                                            values={values}
                                          />
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Grid>
                                ) : (
                                  !isBooleanColumn(
                                    values.filters[index].columnName,
                                    columnNameList,
                                  ) && (
                                    <Grid item xs={5}>
                                      <FieldArray
                                        name={`filters[${index}].values`}
                                        render={({ push: pushValue, remove: removeValue }) => (
                                          <>
                                            {filter.values.map((value: any, valueIndex: any) => (
                                              <Grid container key={valueIndex}>
                                                <Grid item xs={8}>
                                                  <SimpleInputField
                                                    name={`filters[${index}].values[${valueIndex}]`}
                                                    size="small"
                                                    placeholder={`Enter value ${valueIndex + 1}`}
                                                    variant="standard"
                                                    fullWidth
                                                  />
                                                </Grid>
                                                <Grid item xs={4} className={styles.iconsContainer}>
                                                  <Grid container>
                                                    <Grid item xs={4}>
                                                      {valueIndex > 0 && (
                                                        <Tooltip title="Remove Value">
                                                          <IconButton
                                                            onClick={() => removeValue(valueIndex)}>
                                                            <RemoveIcon
                                                              className={styles.removeIcon}
                                                            />
                                                          </IconButton>
                                                        </Tooltip>
                                                      )}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                      {valueIndex === filter.values.length - 1 && (
                                                        <Tooltip title="Add Value">
                                                          <IconButton onClick={() => pushValue('')}>
                                                            <AddIcon className={styles.addIcon} />
                                                          </IconButton>
                                                        </Tooltip>
                                                      )}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                      {valueIndex < filter.values.length - 1 && (
                                                        <IconButton className={styles.OrIcon}>
                                                          OR
                                                        </IconButton>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            ))}
                                          </>
                                        )}
                                      />
                                    </Grid>
                                  )
                                )}
                              </Grid>
                            );
                          })}
                        </Box>
                        <Box className={styles.bottomBox}>
                          <Button
                            onClick={() =>
                              push({ columnName: '', operatorName: '', values: [''] })
                            }>
                            {intl.formatMessage({
                              id: I18nKey.LINKED_IN_ADDFILTER,
                            })}
                          </Button>
                          <Button onClick={handleClearAllFilter}>
                            {intl.formatMessage({
                              id: I18nKey.LINKED_IN_CLEAR,
                            })}
                          </Button>
                          <Button type="submit" variant="contained">
                            {intl.formatMessage({
                              id: I18nKey.LINKED_IN_SEARCH,
                            })}
                          </Button>
                        </Box>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          </Formik>
        </Box>
      </MisPopover>
    </Box>
  );
};

export default CustomizeFilter;
