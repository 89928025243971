import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import useAllAuditLogsStyles from './AllAuditLogsStyles';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import AuditLogsList from './AuditLogsList';
import AuditLogClient from '../../api/AuditLogs/AuditLogsAPIs';
import { generateRows } from '../../components/Datatable/datatableUtils';
import { auditlogIdName } from './AuditLogsListTableConfig';

const AllAuditLogs = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const styles = useAllAuditLogsStyles();
  const { showSnackbar } = useDisplaySnackbar();

  const intl = useIntl();
  const getTableRows = (data: any) => {
    const formattedData = data.map((el: any, index: number) => ({ key: index, ...el }));
    return generateRows(formattedData, [], [], auditlogIdName);
  };

  const getAuditLogList = () => {
    setIsLoading(true);
    const auditLogsParams = {
      start_date: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
    };
    AuditLogClient.getAuditLogs(auditLogsParams)
      .then((response: any) => {
        const rows = getTableRows(response?.data);
        setTableRows(rows);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAuditLogList(), [fetch]);

  return (
    <>
      <Box className={styles.auditLogsWrapper}>
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.AUDITLOG_TITLE,
            })}
          </span>
        </Box>
        <Box className={styles.contentWrapper}>
          <Box className={styles.ListWrapper}>
            <AuditLogsList tableRows={tableRows} apiLoading={isLoading} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AllAuditLogs;
