import { Box, IconButton, Modal } from '@mui/material';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SidePanel from '../../components/SidePanel/SidePanel';
import CustomerRelationClient from '../../api/Opportunity/opportunityAPIs';
import { generateRows } from '../../components/Datatable/datatableUtils';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { dynamicColumns, customerIdName } from './customerListTableConfig';
import useAllCustomersStyles from './AllCustomersStyles';
import AddCustomer from '../Opportunity/AddCustomerForm';
import { getFullName } from '../Opportunity/entitiesFormConfig';
import CustomerList from './CustomerList';

const AllCustomers = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customerList, setCustomerList] = useState<any>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [fetch, setFetch] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);

  const styles = useAllCustomersStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();

  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);
  const handleAddClick = () => setIsOpen(!isOpen);
  const handleModalClose = () => setIsOpen(false);

  const getAllCustomerList = () => {
    setIsLoading(true);
    CustomerRelationClient.getAllCustomerList()
      .then((result) => {
        const rows = generateRows(result, dynamicColumns, [], customerIdName);
        setTableRows(rows);
        setCustomerList(result);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAllCustomerList(), [fetch]);

  useEffect(() => {
    if (id && customerList.length) {
      const {
        name,
        addressFirstLine,
        addressSecondLine,
        city,
        state,
        country,
        pincode,
        poc,
        accountPayablePoc,
      } = customerList.find((account: any) => account.uid === id);

      setSelectedCustomer({
        id,
        name: name || '',
        addressFirstLine: addressFirstLine || '',
        addressSecondLine: addressSecondLine || '',
        city: city || '',
        state: state || '',
        country: country || '',
        pincode: pincode || '',
        poc: poc.map((contact: any) => ({
          label: `${getFullName(contact)} (${contact.email})`,
          value: contact.uid,
        })),
        accountPayablePoc: accountPayablePoc.map((contact: any) => ({
          label: `${getFullName(contact)} (${contact.email})`,
          value: contact.uid,
        })),
      });
    }
  }, [id, customerList]);

  const handleClose = () => {
    setSelectedCustomer(null);
    navigate('/customer');
  };

  return (
    <Box className={styles.customerWrapper}>
      <Box className={styles.Header}>
        <span>
          {intl.formatMessage({
            id: I18nKey.CUSTOMER_TITLE,
          })}
        </span>
      </Box>
      <Box className={styles.contentWrapper}>
        <Box
          className={selectedCustomer ? `${styles.ListWrapper} ${styles.w75}` : styles.ListWrapper}>
          <CustomerList
            tableRows={tableRows}
            updateFetch={updateFetch}
            handleCloseOnDelete={handleClose}
            apiLoading={isLoading}
          />
        </Box>
        {selectedCustomer && (
          <Box className={styles.detailsWrapper} key={selectedCustomer.id}>
            <Box className={`${styles.topContainer} ${styles.card}`}>
              <strong>{selectedCustomer.name}</strong>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={`${styles.bottomContainer} ${styles.card}`}>
              <Box className={styles.customerFormWrapper}>
                <AddCustomer
                  customerInfo={selectedCustomer}
                  handleModalClose={handleClose}
                  updateFetch={updateFetch}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={`${styles.iconbutton} ${styles.button} `} onClick={handleAddClick}>
        <AddIcon />
      </Box>
      <Modal open={isOpen} onClose={handleModalClose}>
        <SidePanel
          header={intl.formatMessage({
            id: I18nKey.CUSTOMER_RELATION_CUSTOMER,
          })}
          onClose={handleModalClose}>
          <div className={styles.formContainer}>
            <AddCustomer handleModalClose={handleModalClose} updateFetch={updateFetch} />
          </div>
        </SidePanel>
      </Modal>
    </Box>
  );
};

export default AllCustomers;
