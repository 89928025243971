import { useContext, useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { AddCircleOutlined, CloseOutlined, InfoOutlined } from '@mui/icons-material';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAddFamilyStyles from './AddFamilyDetailsStyles';
import { dateHyphenFormatter } from '../../utils/utils';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import SimpleCheckboxField from '../../components/SimpleCheckboxField/SimpleCheckboxField';
import familyDetailsClient from '../../api/Family/familyAPIs';
import {
  addFamilyDetialsFormValidation,
  childRelation,
  gender,
  getUpdatedInfo,
  parentRelation,
  spouseGenderMapping,
  spouseRelation,
} from './utils';

const AddFamilyDetails: React.FC<any> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { employeeUid } = useContext(GlobalContext);
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useAddFamilyStyles();

  const [status, setStatus] = useState('');
  const [spouseUid, setSpouseUid] = useState('');
  const [putUid, setPutUid] = useState('');
  const [showSpouseForm, setShowSpouseForm] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    dob: '',
    isMarried: false,
    spouseFirstName: '',
    spouseMiddleName: '',
    spouseLastName: '',
    spouseDob: '',
    spouseRelation: '',
    childrenData: [],
    parentsData: [],
  });
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const [viewMode, setViewMode] = useState(queryParams.viewMode);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
    setShowSpouseForm(value);
  };

  const getFamilyMemberData = (data: any) => {
    return data.map((v: any) => {
      return {
        uid: v?.uid,
        firstName: v.firstName,
        lastName: v.lastName,
        middleName: v.middleName || null,
        dob: v.dob,
        relation: v.relation.label,
      };
    });
  };

  const setFamilyMemberPutData = (data: any, children?: boolean) => {
    if (children)
      return data.map((v: any) => {
        return {
          uid: v.uid,
          firstName: v.firstName,
          lastName: v.lastName,
          middleName: v.middleName,
          dob: v.dob,
          relation: childRelation.find((rel) => rel.label === v.relation),
        };
      });
    return data.map((v: any) => {
      return {
        uid: v.uid,
        firstName: v.firstName,
        lastName: v.lastName,
        middleName: v.middleName,
        dob: v.dob,
        relation: parentRelation.find((rel) => rel.label === v.relation),
      };
    });
  };

  const getDependentPayloadInfo = (formData: any) => {
    const childInfo = getFamilyMemberData(formData.childrenData);
    const parentInfo = getFamilyMemberData(formData.parentsData);
    const childrenData = getUpdatedInfo(childInfo);
    const parentsData = getUpdatedInfo(parentInfo);

    const data = {
      employeeInfo: {
        firstName: formData.firstName,
        middleName: formData.middleName || null,
        lastName: formData.lastName,
        dob: formData.dob,
        gender: formData.gender.label,
        isMarried: formData.isMarried || false,
        uid: putUid,
      },
      familyMembersInfo: [...childrenData, ...parentsData],
    };
    if (formData.isMarried) {
      const spouseData = {
        firstName: formData.spouseFirstName,
        middleName: formData?.spouseMiddleName || null,
        lastName: formData.spouseLastName,
        dob: formData.spouseDob,
        relation: formData.spouseRelation.label,
        uid: spouseUid,
      };
      data.familyMembersInfo = data.familyMembersInfo.concat(spouseData);
      return data;
    }
    return data;
  };

  const handleOnchangeGender = (changedData: { label: 'Male' | 'Female'; value: string }) => {
    setInitialValues({
      ...initialValues,
      gender: changedData,
      spouseRelation: spouseRelation.find(
        (v) => v.label !== spouseGenderMapping[changedData.label],
      ),
    });
  };
  const setSpouseRelation = (relation: string, gnder: 'Male' | 'Female') => {
    if (relation) return spouseRelation.find((v) => v.label === relation);
    return spouseRelation.find((v) => v.label !== spouseGenderMapping[gnder]);
  };
  const getEmployeeDetails = () => {
    setIsLoading(true);
    familyDetailsClient
      .getEmployeeDetails(employeeUid)
      .then((res: any) => {
        if (res?.status === 'Submitted' && viewMode !== 'false') {
          navigate(`/myFamily/viewMode/${id}`);
        }
        const setInitialInfo = {
          firstName: res.firstName,
          middleName: res?.middleName,
          lastName: res.lastName,
          gender: gender.find((v) => v.label === res.gender),
          dob: res.dob,
          isMarried: res.isMarried,
          spouseFirstName: res?.spouseFirstName,
          spouseMiddleName: res?.spouseMiddleName,
          spouseLastName: res?.spouseLastName,
          spouseDob: res?.spouseDob,
          spouseRelation: setSpouseRelation(res.spouseRelation, res.gender),
          childrenData: setFamilyMemberPutData(res.childrenData, true),
          parentsData: setFamilyMemberPutData(res.parentsData),
        };
        setInitialValues({ ...setInitialInfo });
        setShowSpouseForm(res.isMarried);
        setStatus(res.status);
        setPutUid(res.uid);
        setSpouseUid(res?.spouseUid);
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    const payload = getDependentPayloadInfo(values);
    familyDetailsClient
      .updateFamilyDetails(payload, employeeUid)
      .then((res: any) => {
        showSnackbar(res, 'success');
        setViewMode('');
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getEmployeeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.addFamilyWrapper}>
        <Box className={styles.Header}>
          <span className="headerTitle">
            {intl.formatMessage({
              id: I18nKey.FAMILY_ADD_DEPENDENTS,
            })}
          </span>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={addFamilyDetialsFormValidation}
          enableReinitialize
          validateOnMount>
          {(formik) => {
            const { values, setFieldValue, dirty, isValid, handleBlur, errors } = formik;
            return (
              <Form className={styles.formWrapper}>
                <Grid container rowSpacing={2} spacing={1}>
                  <Grid item xs={12}>
                    <Divider>
                      <Chip label="Employee Details" className="divider-chip" />
                    </Divider>
                  </Grid>
                  <Grid item xs={2.4}>
                    <SimpleInputField
                      name="firstName"
                      type="string"
                      label="First Name"
                      size="small"
                      onBlur={(event: any) => {
                        handleBlur(event);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={2.4}>
                    <SimpleInputField
                      name="middleName"
                      type="string"
                      label="Middle Name"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2.4}>
                    <SimpleInputField
                      name="lastName"
                      type="string"
                      label="Last Name"
                      size="small"
                      onBlur={(event: any) => {
                        handleBlur(event);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={2.4}>
                    <SimpleAutocompleteField
                      name="gender"
                      key="gender"
                      label="Gender"
                      size="small"
                      handleChange={(e: any, v: any) => handleOnchangeGender(v)}
                      setFieldValue={setFieldValue}
                      options={gender}
                      values={values}
                      className={styles.formField}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2.4}>
                    <>
                      <SimpleInputField
                        className={styles.formField}
                        name="dob"
                        type="date"
                        label="Date of Birth"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        required
                      />
                      <Box className={styles.dateFormatInfo}>{dateHyphenFormatter(values.dob)}</Box>
                    </>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={styles.checkboxWrapper}>
                      <SimpleCheckboxField
                        name="isMarried"
                        checked={values.isMarried}
                        inputProps={{ style: { fontSize: 13 } }}
                        setFieldValue={setFieldValue}
                        label="Married?"
                        handleChange={handleCheckboxChange}
                        size="small"
                      />
                      <Tooltip
                        title={intl.formatMessage({
                          id: I18nKey.FAMILY_SKIP_SPOUSE,
                        })}
                        placement="left">
                        <InfoOutlined color="info" className={styles.info} />
                      </Tooltip>
                    </Box>
                  </Grid>
                  {showSpouseForm && (
                    <>
                      <Grid item xs={12}>
                        <Typography className={styles.textInfo}>
                          {intl.formatMessage({
                            id: I18nKey.FAMILY_SPOUSE_INFO,
                          })}
                        </Typography>
                      </Grid>
                      <Grid item xs={2.4}>
                        <SimpleInputField
                          name="spouseFirstName"
                          type="string"
                          label="First Name"
                          size="small"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={2.4}>
                        <SimpleInputField
                          name="spouseMiddleName"
                          type="string"
                          label="Middle Name"
                          size="small"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2.4}>
                        <SimpleInputField
                          name="spouseLastName"
                          type="string"
                          label="Last Name"
                          size="small"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={2.4}>
                        <SimpleAutocompleteField
                          name="spouseRelation"
                          key="relation"
                          label="Relation"
                          size="small"
                          setFieldValue={setFieldValue}
                          options={spouseRelation}
                          values={values}
                          className={styles.formField}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2.4}>
                        <SimpleInputField
                          className={styles.formField}
                          name="spouseDob"
                          type="date"
                          label="Date of Birth"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          required
                        />
                        <Box className={styles.dateFormatInfo}>
                          {dateHyphenFormatter(values.spouseDob)}
                        </Box>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Divider>
                      <Chip label="Additional Family Details" className="divider-chip" />
                    </Divider>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={styles.textInfo}>
                      {intl.formatMessage({
                        id: I18nKey.FAMILY_CHILD_INFO,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name="childrenData"
                      render={(arrayHelpers) => {
                        return (
                          <>
                            {values.childrenData?.map((item: any, index: number) => (
                              <>
                                <Box className={styles.dataBox}>
                                  <Grid container rowSpacing={2} spacing={1}>
                                    <Grid item xs={12}>
                                      <Typography className={styles.textSubHeadings}>
                                        Child {index + 1}{' '}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2.4}>
                                      <SimpleInputField
                                        name={`childrenData[${index}].firstName`}
                                        type="string"
                                        label="First Name"
                                        size="small"
                                        fullWidth
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                      <SimpleInputField
                                        name={`childrenData[${index}].middleName`}
                                        type="string"
                                        label="Middle Name"
                                        size="small"
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                      <SimpleInputField
                                        name={`childrenData[${index}].lastName`}
                                        type="string"
                                        label="Last Name"
                                        size="small"
                                        fullWidth
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                      <Autocomplete
                                        fullWidth
                                        id="relation"
                                        size="small"
                                        disableClearable
                                        options={childRelation}
                                        value={values.childrenData[index].relation}
                                        onChange={(event, value) => {
                                          setFieldValue(`childrenData[${index}].relation`, value);
                                        }}
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(param) => (
                                          <TextField {...param} label="Relation" required />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                      <SimpleInputField
                                        name={`childrenData[${index}].dob`}
                                        type="date"
                                        label="Date Of Birth"
                                        size="small"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        fullWidth
                                        required
                                      />
                                      <Box className={styles.dateFormatInfo}>
                                        {dateHyphenFormatter(values.childrenData[index].dob)}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  <Button
                                    size="small"
                                    className={styles.dataRemoveBtn}
                                    sx={{ position: 'absolute' }}
                                    onClick={() => arrayHelpers.remove(index)}>
                                    <CloseOutlined />
                                  </Button>
                                </Box>
                              </>
                            ))}
                            {values.childrenData?.length < 2 && (
                              <Button
                                className={styles.addBtn}
                                onClick={() =>
                                  arrayHelpers.push({
                                    firstName: '',
                                    lastName: '',
                                    middleName: '',
                                    dob: '',
                                    relation: null,
                                  })
                                }>
                                <AddCircleOutlined />
                              </Button>
                            )}
                          </>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={styles.textInfo}>
                      {intl.formatMessage({
                        id: I18nKey.FAMILY_PARENTS_INFO,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name="parentsData"
                      render={(arrayHelpers) => {
                        return (
                          <>
                            {values.parentsData?.map((item: any, index: number) => (
                              <>
                                <Box className={styles.dataBox}>
                                  <Grid container rowSpacing={2} spacing={1}>
                                    <Grid item xs={2.4}>
                                      <SimpleInputField
                                        name={`parentsData[${index}].firstName`}
                                        type="string"
                                        label="First Name"
                                        size="small"
                                        fullWidth
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                      <SimpleInputField
                                        name={`parentsData[${index}].middleName`}
                                        type="string"
                                        label="Middle Name"
                                        size="small"
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                      <SimpleInputField
                                        name={`parentsData[${index}].lastName`}
                                        type="string"
                                        label="Last Name"
                                        size="small"
                                        fullWidth
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                      <Autocomplete
                                        fullWidth
                                        id="relation"
                                        size="small"
                                        disableClearable
                                        options={parentRelation}
                                        value={values.parentsData[index].relation}
                                        onChange={(event, value) => {
                                          setFieldValue(`parentsData[${index}].relation`, value);
                                        }}
                                        getOptionLabel={(option) => option.label}
                                        renderInput={(param) => (
                                          <TextField {...param} label="Relation" required />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={2.4}>
                                      <SimpleInputField
                                        name={`parentsData[${index}].dob`}
                                        type="date"
                                        label="Date Of Birth"
                                        size="small"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        fullWidth
                                        required
                                      />
                                      <Box className={styles.dateFormatInfo}>
                                        {dateHyphenFormatter(values.parentsData[index].dob)}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  <Button
                                    size="small"
                                    className={styles.dataRemoveBtn}
                                    sx={{ position: 'absolute' }}
                                    onClick={() => arrayHelpers.remove(index)}>
                                    <CloseOutlined />
                                  </Button>
                                </Box>
                              </>
                            ))}
                            {values.parentsData.length < 2 && (
                              <Button
                                className={styles.addBtn}
                                onClick={() =>
                                  arrayHelpers.push({
                                    firstName: '',
                                    lastName: '',
                                    middleName: '',
                                    dob: '',
                                    relation: null,
                                  })
                                }>
                                <AddCircleOutlined />
                              </Button>
                            )}
                          </>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  disabled={!isValid}
                  variant="contained"
                  type="submit"
                  className={styles.submitBtn}>
                  {intl.formatMessage({
                    id: I18nKey.BUTTON_LABEL_SUBMIT,
                  })}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default AddFamilyDetails;
