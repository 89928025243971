import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import Datatable from '../../components/Datatable/Datatable';
import {
  serialNumber,
  dynamicColumns,
  tableRowTotalField,
  columns,
  initialShowCols,
  getInitialVisibilityModel,
} from './AllEmpDependentConfig';
import useAddFamilyStyles from './AddFamilyDetailsStyles';
import I18nKey from '../../translations/I18nKey';
import { generateRows } from '../../components/Datatable/datatableUtils';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import familyDetailsClient from '../../api/Family/familyAPIs';
import { GlobalContext } from '../../contexts/GlobalContext';

// eslint-disable-next-line react/prop-types
const AllEmpDependents: React.FC<any> = ({ viewMode = false }) => {
  const [tableColumns, setTableColumns] = useState<any[]>(columns);
  const { employeeUid } = useContext(GlobalContext);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const styles = useAddFamilyStyles();
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }),
  );
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();

  const getEmpDependentsPromise = () => {
    if (viewMode) return familyDetailsClient.getSelfDependents(true, employeeUid);
    return familyDetailsClient.getAllEmpDependents(employeeUid);
  };

  const generateDatatableInputs = () => {
    setIsLoading(true);
    getEmpDependentsPromise()
      .then((response) => {
        const rows = generateRows(response.data, dynamicColumns, tableRowTotalField, serialNumber);
        setTableRows(rows);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };
  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  useEffect(() => {
    generateDatatableInputs();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetch]);

  useEffect(() => {
    setTableColumns(columns);
    const updatedTableCols: any = [];
    Object.keys(columnVisibilityModel).forEach((col: string) => {
      if (columnVisibilityModel[col] !== false) updatedTableCols.push(col);
    });

    setShowColumns(updatedTableCols);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [columnVisibilityModel]);

  return (
    <>
      <Box className={styles.allEmpDependentsWrapper}>
        {!viewMode && (
          <Box className={styles.Header}>
            <span>
              {intl.formatMessage({
                id: I18nKey.EMP_DEPENDENTS,
              })}
            </span>
          </Box>
        )}
        <Datatable
          loading={isLoading}
          rows={tableRows}
          columns={tableColumns}
          showSearch={!viewMode}
          showExportButton={!viewMode}
          columnVisibility={!viewMode}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          exportedFileName={`Dependents_List_${dayjs().format('DD_MMMM')}`}
          skipConfirmation
          tableHeight={viewMode ? '54vh' : '76vh'}
          className={viewMode ? styles.table : ''}
          showFilterButton={!viewMode}
          hideFooterPagination={viewMode}
          showRefreshButton={!viewMode}
          getUpdatedList={generateDatatableInputs}
        />
      </Box>
    </>
  );
};

export default AllEmpDependents;
