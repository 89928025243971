/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Modal, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { useIntl } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import dayjs from 'dayjs';
import VacanciesClient, { PATHS as VacancyPaths } from '../../api/Vacancies/vacanciesAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import Datatable from '../../components/Datatable/Datatable';
import {
  contactId,
  dynamicColumns,
  tableRowTotalField,
  getColumns,
  initialShowCols,
  getInitialVisibilityModel,
  initialSort,
} from './vacanciesTableConfig';
import useAllVacanciesStyles from './AllVacanciesStyles';
import I18nKey from '../../translations/I18nKey';
import { generateRows } from '../../components/Datatable/datatableUtils';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import AddVacancyForm from './AddVacancyForm';
import SidePanel from '../../components/SidePanel/SidePanel';
import MasterClient from '../../api/Master/masterAPIs';
import { ApiOperations, getMatchedUids, getProcessFields } from '../../utils/utils';
import MisDialog from '../../components/MisDialog/MisDialog';
import {
  addVacancyPayload,
  transformGetVacancyResponse,
  transformVacancyDataFromUpdateOperation,
  updateVacancyPayload,
  updatedRemarks,
} from './utils';
import AddDocumentForm from '../Interview/Forms/AddDocumentForm';
import DocumentClient from '../../api/Document/DocumentAPI';
import { Priority, Status } from './VacancyTypes';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};
const AllVacancies = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const styles = useAllVacanciesStyles();
  const [vacancyId, setVacancyId] = useState('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [projectList, setProjectList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [AllSkills, setAllSkills] = useState([]);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState('');
  const [fetch, setFetch] = useState<number>(0);
  const [vacancyData, setVacancyData] = useState<any>(null);
  const [tabValue, setTabValue] = useState(0);

  const [formState, setFormState] = useState({
    0: {
      designation: '',
      jobDescription: [],
      positions: null,
      priority: '',
      status: '',
      location: '',
      closureDate: '',
      projects: [],
      customers: [],
      remarks: '',
      jobDescriptionDetail: '',
    },
    1: {},
  });

  const generalFormRef = useRef();
  const jobDescriptionFormRef = useRef();

  const formRefDict: Record<number, any> = {
    0: generalFormRef,
    1: jobDescriptionFormRef,
  };
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);
  const ref = useRef<HTMLDivElement | null | any>(null);
  const [selectedVacancyUid, setSelectedVacancyUid] = useState<string>('');

  const cloneVacancy = (params: any) => {
    const action = VacanciesClient.cloneVacanciesDetails(params.row.uid);

    action
      .then((result: any) => {
        showSnackbar(result, 'success');
        updateFetch();
        ref.current?.cloned();
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };
  const rowActions = [
    {
      label: 'Edit',
      logo: (
        <Tooltip title="Edit">
          <EditIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        setTabValue(0);
        setVacancyId(params.row.uid);
        setSelectedVacancyUid(params.row.uid);
        const vacancyDataPayload = transformVacancyDataFromUpdateOperation(params);

        setFormState({
          ...formState,
          0: {
            designation: vacancyDataPayload.designation || '',
            jobDescription: vacancyDataPayload.jobDescription || [],
            positions: vacancyDataPayload.positions?.label,
            priority: vacancyDataPayload?.priority?.label || '',
            status: vacancyDataPayload?.status?.label || '',
            location: vacancyDataPayload.location || '',
            closureDate: vacancyDataPayload?.closureDate || '',
            projects: vacancyDataPayload.projects,
            customers: vacancyDataPayload.customers,
            remarks: vacancyDataPayload.remarks || '',
            jobDescriptionDetail: vacancyDataPayload.jobDescriptionDetail || '',
          },
          1: { documentName: vacancyDataPayload.jdDocumentName },
        });
        setVacancyData(vacancyDataPayload);
        setIsEdit(true);
        setModalOpen(true);
      },
      path: VacancyPaths.PUT_VACANCY,
      operation: ApiOperations.PUT,
    },
    {
      label: 'Delete',
      logo: (
        <Tooltip title="Delete">
          <DeleteIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteCandidateId(params.row.id);
      },
      path: VacancyPaths.DELETE_VACANCY,
      operation: ApiOperations.DELETE,
    },
    {
      label: 'Clone',
      logo: (
        <Tooltip title="Clone">
          <FileCopyIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        cloneVacancy(params);
      },
      path: VacancyPaths.CLONE_VACANCY,
      operation: ApiOperations.POST,
    },
    {
      label: 'Download',
      logo: (
        <Tooltip title="Download">
          <DownloadIcon />
        </Tooltip>
      ),
      callback: (params: any) => {
        const downloadLetterParams = {
          preSignedUrl: params.row.jdPresignedUrl,
        };
        DocumentClient.downloadDocument(downloadLetterParams)
          .then((res) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(
              new Blob([res.data], { type: 'application/pdf' }),
            );
            link.download = params.row.jdDocumentName;
            link.click();
          })
          .catch((e) => showSnackbar(e, 'error'));
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }, rowActions),
  );
  const [tableColumns, setTableColumns] = useState<any[]>(getColumns(rowActions));

  const generateDatatableInputs = () => {
    setIsLoading(true);
    VacanciesClient.getVacanciesList()
      .then((response: any) => {
        const transformedData = transformGetVacancyResponse(response.data);
        const rows = generateRows(transformedData, dynamicColumns, tableRowTotalField, contactId);
        setTableRows(rows);
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  const handleRowDataChange = (updatedRows: any[]) => {
    setIsLoading(true);
    const updatedBulkRemarks = updatedRemarks(updatedRows);
    VacanciesClient.updateBulkRemarks(updatedBulkRemarks)
      .then((result: any) => {
        showSnackbar(result, 'success');
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setIsLoading(false));
  };

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setVacancyData(null);
    setIsEdit(false);
    setSelectedVacancyUid('');
    setFormState({
      0: {
        designation: '',
        jobDescription: [],
        positions: null,
        priority: '',
        status: '',
        location: '',
        closureDate: '',
        projects: [],
        customers: [],
        remarks: '',
        jobDescriptionDetail: '',
      },
      1: {},
    });
  };
  const handleSubmit = (input: any) => {
    setSubmitLoading(true);

    const transformPayload = isEdit
      ? updateVacancyPayload(input, AllSkills, customerList, projectList)
      : addVacancyPayload(input);

    const formData = new FormData();
    formData.append('vacancy_details', JSON.stringify(transformPayload));
    const action = isEdit
      ? VacanciesClient.updateVacanciesDetails(vacancyId, formData)
      : VacanciesClient.addVacancies(transformPayload);

    action
      .then((result: any) => {
        if (!isEdit) {
          setSelectedVacancyUid(result.data.vacancy_uid);
          setTabValue(1);
        }
        updateFetch();
        showSnackbar(result, 'success');
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setSubmitLoading(false));
  };

  const fetchProjectsList = () => {
    VacanciesClient.getProjects()
      .then((result: any) => {
        const projects: any = Array.isArray(result.data)
          ? result.data.map((res: { uid: string; projectName: string }) => ({
              value: res.uid,
              label: res.projectName,
            }))
          : [];
        setProjectList(projects);
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      });
  };

  const fetchCustomersList = () => {
    VacanciesClient.getCustomers()
      .then((result: any) => {
        const customers: any = Array.isArray(result.data)
          ? result.data.map((res: { uid: string; name: string }) => ({
              value: res.uid,
              label: res.name,
            }))
          : [];
        setCustomerList(customers);
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      });
  };

  const getSkillsList = () => {
    MasterClient.getSkills().then((result: any) => {
      const skills = Array.isArray(result.data)
        ? result.data.map((res: { uid: string; name: string }) => ({
            value: res.uid,
            label: res.name,
          }))
        : [];
      setAllSkills(skills);
    });
  };

  useEffect(() => {
    generateDatatableInputs();
  }, [fetch]);

  useEffect(() => {
    const updatedTableCols: any = [];
    Object.keys(columnVisibilityModel).forEach((col: string) => {
      if (columnVisibilityModel[col] !== false) updatedTableCols.push(col);
    });

    setShowColumns(updatedTableCols);
  }, [columnVisibilityModel]);

  useEffect(() => {
    fetchProjectsList();
    fetchCustomersList();
    getSkillsList();
  }, []);

  const getVacancyById = () => {
    setSubmitLoading(true);
    VacanciesClient.getVacancy(selectedVacancyUid)
      .then((result: any) => {
        const response: any = result.data;

        const filteredCustomers = customerList.filter(function (item: any) {
          return response.customer_uids.indexOf(item?.value) !== -1;
        });
        const filteredProjects = projectList.filter(function (item: any) {
          return response.project_uids.indexOf(item?.value) !== -1;
        });
        const filteredJobDescription = AllSkills.filter(function (item: any) {
          return response.job_description.indexOf(item?.value) !== -1;
        });
        setFormState({
          ...formState,
          0: {
            designation: response?.designation,
            jobDescription: filteredJobDescription?.length ? filteredJobDescription : [],
            positions: response?.no_of_positions,
            priority: Priority.find((item) => item.value === response?.priority)?.label || '',
            status: Status.find((item) => item.value === response?.status)?.label || '',
            location: response?.location,
            closureDate: response?.expected_closure_date,
            projects: filteredProjects.length ? filteredProjects : [],
            customers: filteredCustomers?.length ? filteredCustomers : [],
            remarks: response?.remarks,
            jobDescriptionDetail: response?.job_description_detail,
          },
          1: { documentName: response?.jd_document_name },
        });
        setVacancyData({
          designation: response?.designation,
          jobDescription: filteredJobDescription?.length ? filteredJobDescription : [],
          positions: response?.no_of_positions,
          priority: Priority.find((item) => item.value === response?.priority)?.label || '',
          status: Status.find((item) => item.value === response?.status)?.label || '',
          location: response?.location,
          closureDate: response?.expected_closure_date,
          projects: filteredProjects.length ? filteredProjects : [],
          customers: filteredCustomers?.length ? filteredCustomers : [],
          remarks: response?.remarks,
          jobDescriptionDetail: response?.job_description_detail,
          jdDocumentName: response?.jd_document_name,
        });
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  useEffect(() => {
    if (selectedVacancyUid) {
      getVacancyById();
    }
  }, [selectedVacancyUid, fetch]);

  const handleModalOpen = () => {
    setModalOpen(true);
    setIsEdit(false);
    setTabValue(0);
    setFormState({
      0: {
        designation: '',
        jobDescription: [],
        positions: null,
        priority: '',
        status: '',
        location: '',
        closureDate: '',
        projects: [],
        customers: [],
        remarks: '',
        jobDescriptionDetail: '',
      },
      1: {},
    });
  };

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteCandidateId) {
      setIsLoading(true);
      VacanciesClient.deleteVacancy(deleteCandidateId)
        .then((result: any) => {
          showSnackbar(result, 'success');
          updateFetch();
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteCandidateId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDeleteCandidateId('');
  };

  const handleTabChange = (event: any, newValue: number) => {
    // Preserving the current state of the form in the global state
    if (formRefDict[tabValue]?.current?.values) {
      const newFormState: any = { ...formState };
      if (tabValue === 1) {
        if (formRefDict[tabValue]?.current?.values?.documentFile) {
          delete formRefDict[tabValue]?.current?.values?.documentFile;
        }
      }
      newFormState[tabValue] = formRefDict[tabValue]?.current?.values;
      setFormState(newFormState);
    }

    formRefDict[newValue]?.current?.validateForm();
    // formRefDict[newValue]?.current?.isDirty = true;
    setTabValue(newValue);
  };

  const handleUploadDocument = (values: any) => {
    setIsLoading(true);
    const file = values.documentFile;
    const formData = new FormData();
    formData.append('vacancy_details', '{}');
    formData.append('job_description_file', file);

    VacanciesClient.updateVacanciesDetails(selectedVacancyUid, formData)
      .then((res: any) => {
        setFormState({
          0: {
            designation: '',
            jobDescription: [],
            positions: null,
            priority: '',
            status: '',
            location: '',
            closureDate: '',
            projects: [],
            customers: [],
            remarks: '',
            jobDescriptionDetail: '',
          },
          1: {},
        });
        setVacancyData(null);
        updateFetch();
        showSnackbar(res, 'success');
        setTabValue(1);
        handleModalClose();
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };
  return (
    <>
      <Box className={styles.allVacanciesWrapper}>
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.VACANCIES_TITLE,
            })}
          </span>
        </Box>
        <Box className={styles.ListWrapper}>
          <Datatable
            ref={ref}
            loading={isLoading || submitLoading}
            rows={tableRows}
            columns={tableColumns}
            columnVisibility
            editable
            updateRows={handleRowDataChange}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            rowCount={tableRows.length || 0}
            showExportButton
            exportedFileName={`Vacancies_${dayjs().format('DD_MMMM')}`}
            initialSort={initialSort as any}
          />
        </Box>
        <Modal open={modalOpen} onClose={handleModalClose}>
          <Box className={styles.formContainer}>
            <SidePanel
              header={
                isEdit
                  ? intl.formatMessage({
                      id: I18nKey.EDIT_VACANCIES_TITLE,
                    })
                  : intl.formatMessage({
                      id: I18nKey.ADD_VACANCIES_TITLE,
                    })
              }
              onClose={handleModalClose}>
              <Box className={styles.sidePanelTabContent}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label="General" />
                  <Tab label="Documents" disabled={isEdit ? false : !selectedVacancyUid} />
                </Tabs>
                <TabPanel index={0} value={tabValue}>
                  <AddVacancyForm
                    formState={formState[0]}
                    formRef={generalFormRef}
                    handleSubmit={handleSubmit}
                    projectList={projectList}
                    customersList={customerList}
                    skillsList={AllSkills}
                    isEdit={isEdit}
                  />
                </TabPanel>
                <TabPanel index={1} value={tabValue}>
                  <AddDocumentForm
                    formRef={jobDescriptionFormRef}
                    handleSubmit={handleUploadDocument}
                    formState={formState[1]}
                    isRequired={false}
                    label="Upload Job Description"
                    name="job_description_file"
                    type=".pdf"
                    acceptedFile={['.pdf']}
                  />
                </TabPanel>
              </Box>
            </SidePanel>
          </Box>
        </Modal>
        <Box className={`${styles.iconbutton} ${styles.button}`} onClick={handleModalOpen}>
          <AddIcon />
        </Box>
      </Box>
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.VACANCIES_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.DELETE_VACANCIES_TEXT,
        })}
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel="Delete"
      />
    </>
  );
};

export default AllVacancies;
