import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Modal } from '@mui/material';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import I18nKey from '../../translations/I18nKey';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { generateRows } from '../../components/Datatable/datatableUtils';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { ApiOperations } from '../../utils/utils';
import {
  categoryUid,
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
  initialSort,
} from './CategoryListTableConfig';
import useCategoryStyles from './CategoryStyles';
import TicketClient, { PATHS as CategoryPaths } from '../../api/Ticket/TicketAPIs';
import SidePanel from '../../components/SidePanel/SidePanel';
import AddCategoryForm from './AddCategoryForm';

const CategoryList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [categoryId, setCategoryId] = useState('');
  const [fetchCategoryList, setFetchCategoryList] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [category, setCategory] = useState<any>({
    name: '',
    description: '',
  });

  const updateFetchCategoryList = () => setFetchCategoryList((fetchNum) => fetchNum + 1);
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useCategoryStyles();

  const rowActions = [
    {
      label: 'Edit',
      logo: <EditIcon />,
      callback: (params: any) => {
        setOpen(true);
        setCategoryId(params.id);
        setCategory({
          name: params.row.name,
          description: params.row.description,
        });
        setIsEdit(true);
      },
      path: CategoryPaths.PUT_CATEGORY,
      operation: ApiOperations.PUT,
    },
  ];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }, rowActions),
  );
  const [tableColumns, setTableColumns] = useState<any[]>(getColumns(rowActions));

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, [], [], categoryUid);
    return rows;
  };

  const generateDatatableInputs = () => {
    setIsLoading(true);
    TicketClient.getAllCategories()
      .then((response: any) => {
        const rows = getTableRows(response);
        setTableRows(rows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    generateDatatableInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategoryList]);

  const handleModalOpen = () => {
    setOpen(!open);
  };

  const handleModalClose = () => {
    setOpen(!open);
    setCategory({
      name: '',
      description: '',
    });
    setCategoryId('');
    updateFetchCategoryList();
    setIsEdit(false);
  };

  const handleAddCategory = (values: any) => {
    setIsLoading(true);
    TicketClient.createCategory(values)
      .then((result) => {
        showSnackbar(result, 'success');
        handleModalClose();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleUpdateCategory = (values: any) => {
    setIsLoading(true);
    TicketClient.updateCategoryDetails(categoryId, values)
      .then((result) => {
        showSnackbar(result, 'success');
        handleModalClose();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (values: any) => {
    if (categoryId) {
      handleUpdateCategory(values);
    } else {
      handleAddCategory(values);
    }
  };

  return (
    <>
      <Box className={styles.categoryWrapper}>
        <Box className={styles.allHeader}>
          <span>
            {intl.formatMessage({
              id: I18nKey.CATEGORY,
            })}
          </span>
          <Box className={styles.linkWrapper}>
            <Link to="/ticket">
              {intl.formatMessage({
                id: I18nKey.DASHBOARD,
              })}
            </Link>{' '}
            /{' '}
            {intl.formatMessage({
              id: I18nKey.CATEGORY,
            })}
          </Box>
        </Box>
        <>
          <Datatable
            loading={isLoading}
            rows={tableRows}
            columns={tableColumns} /* eslint-disable react/jsx-boolean-value */
            columnVisibility={false}
            initialSort={initialSort as any}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            showExportButton
            exportedFileName={`Category_List_${dayjs().format('DD_MMMM')}`}
          />
        </>
        <>
          <Box className={`${styles.iconButton} ${styles.button}`} onClick={handleModalOpen}>
            <AddIcon />
          </Box>
        </>
        <Modal open={open} onClose={handleModalClose}>
          <Box className={styles.formContainer}>
            <SidePanel
              header={
                isEdit
                  ? intl.formatMessage({
                      id: I18nKey.EDIT_CATEGORY,
                    })
                  : intl.formatMessage({
                      id: I18nKey.ADD_CATEGORY,
                    })
              }
              onClose={handleModalClose}>
              <AddCategoryForm handleSubmit={handleSubmit} category={category} isEdit={isEdit} />
            </SidePanel>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default CategoryList;
