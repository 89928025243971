import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ResourceClient from '../../api/Resource/resourceAPIs';
import { Project } from '../../api/Resource/resourceTypes';
import Datatable from '../../components/Datatable/Datatable';
import { generateRows } from '../../components/Datatable/datatableUtils';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import {
  columns,
  dateFrom,
  dynamicColumns,
  getInitialVisibilityModel,
  initialShowCols,
  initialSort,
  resourceIdName,
  tableRowTotalField,
} from './resourceListConfig';
import { filterRows } from '../../components/CustomFilter/FilterUtils';
import CustomizeFilter from '../../components/CustomFilter/CustomizeFilter';
import useAllResourceListStyles from './ResourceListStyle';

interface ResourceListProps {
  readonly resourceListData: any;
  readonly updateFetch: () => void;
  readonly apiLoading: boolean;
}

export const columnResourceForFilterList = [
  { value: 'employeeName', label: 'Name', type: 'string' },
  { value: 'employeeDesignation', label: 'Designation', type: 'string' },
  { value: 'managerName', label: 'Manager Name', type: 'string' },
  { value: 'remarks', label: 'Remarks', type: 'string' },
  { value: 'opportunity', label: 'Parked', type: 'string' },
  { value: 'isInterviewer', label: 'Interviewer Role', type: 'string' },
  { value: 'review', label: 'Review', type: 'boolean' },
  { value: 'primarySkills', label: 'Primary Skills', type: 'array' },
  { value: 'otherSkills', label: 'Other Skills', type: 'array' },
  { value: 'primaryProject', label: 'Primary Project', type: 'string' },
  { value: 'secondaryProjects', label: 'Secondary Projects', type: 'string' },
  { value: 'billed', label: 'Billed', type: 'boolean' },
  { value: 'allocation', label: 'Allocation', type: 'number' },
];

const ResourceList: React.FC<ResourceListProps> = ({
  resourceListData,
  updateFetch,
  apiLoading,
}) => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState<any[]>(columns({}));
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [updateReview, setUpdateReview] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }),
  );
  const [isExpanded, setExpanded] = useState(true);
  const [initialValues, setInitialValues] = useState({
    filters: [{ columnName: '', operatorName: '', values: [''] }],
  });
  const [filteredData, setFilteredData] = useState<any>(null);
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useAllResourceListStyles();

  const handleClearAllFilter = () => {
    setInitialValues({
      filters: [{ columnName: '', operatorName: '', values: [''] }],
    });
    setFilteredData(null);
  };

  const handleExpanded = (rowId: any) => {
    setExpanded(!isExpanded);
  };

  const handleReviewChange = (rowId: string, e: boolean) => {
    const updatedRows = tableRows.map((row) => {
      if (row.id === rowId) {
        setUpdateReview([{ employeeUid: rowId, review: !e }]);
        return { ...row, review: !e };
      }
      return row;
    });
    setTableRows(updatedRows);
  };

  const getBilledStatus = (newRowProjectDetails: any) => {
    let result: boolean | null = false;
    for (let i = 0; i < newRowProjectDetails.length; i += 1) {
      const el = newRowProjectDetails[i];
      if (el.isBilled === true) {
        return true;
      }
      if (el.isBilled === null) {
        result = null;
      }
    }
    return result;
  };

  const generateResourceRows = (rows: any) => {
    const updatedTableRows: any = [];
    rows.forEach((row: any) => {
      const newRow = { ...row };
      const newRowProjectDetails = newRow.projectDetails
        ? _.orderBy(newRow.projectDetails, ['isPrimary'], ['desc'])
        : [];
      newRow.primaryProject = 'ToBeAllocated';
      newRow.secondaryProjects = '-';
      newRow.employeeName = newRow.employeeFirstName.concat(' ', newRow.employeeLastName);
      newRow.managerName = newRow.managerFirstName?.concat(' ', newRow.managerLastName);

      if (newRowProjectDetails.length) {
        const primaryProject = newRowProjectDetails
          .filter((p: any) => p.isPrimary === 1)
          .map((pr: any) => pr.projectName)
          .join(', ');
        if (primaryProject) newRow.primaryProject = primaryProject;

        const secondaryProject = newRowProjectDetails
          .filter((p: any) => p.isPrimary === 0)
          .map((pr: any) => pr.projectName)
          .join(', ');
        if (secondaryProject) newRow.secondaryProjects = secondaryProject;
      }

      const currMonthProjectDetail = newRowProjectDetails.filter(
        (p: any) =>
          dayjs(dateFrom).isSameOrAfter(p.startDate, 'month') &&
          dayjs(dateFrom).isSameOrBefore(p.endDate, 'month'),
      );

      newRow.allocation = currMonthProjectDetail.reduce((acc: number, curr: Project) => {
        return acc + curr.allocationPercentage;
      }, 0);

      newRow.billed = getBilledStatus(currMonthProjectDetail);

      newRow[dateFrom] = currMonthProjectDetail.map((proj: any) => proj.projectName).join(', ');

      updatedTableRows.push(newRow);
    });
    return updatedTableRows;
  };

  const handleSubmit = (v: any) => {
    setInitialValues(v);
    const transformedData: any = {
      advanced_filters: {},
    };
    v.filters.forEach((filter: any) => {
      const columnName = filter.columnName.value;
      const operations = filter.operatorName.value;
      const { values } = filter;
      if (values.length > 0) {
        if (!transformedData.advanced_filters[columnName]) {
          transformedData.advanced_filters[columnName] = [];
        }
        const existingEntry = transformedData.advanced_filters[columnName]?.find(
          (entry: any) => entry.operation === operations,
        );
        if (existingEntry) {
          existingEntry.data = existingEntry.data.concat(values);
        } else {
          transformedData.advanced_filters[columnName].push({
            data: values,
            operation: operations,
          });
        }
      }
    });
    setFilteredData(transformedData.advanced_filters);
  };

  useEffect(() => {
    if (resourceListData) {
      const rows = generateRows(
        resourceListData,
        dynamicColumns,
        tableRowTotalField,
        resourceIdName,
      );
      const initialRows = generateResourceRows(rows);
      const filteredRows = filterRows(initialRows, filteredData, columnResourceForFilterList);
      const generatedResourceRows = generateResourceRows(filteredRows);
      setTableRows(generatedResourceRows);
      setColumnVisibilityModel(getInitialVisibilityModel({ showColumns }));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [resourceListData, filteredData]);

  useEffect(() => {
    if (updateReview.length) {
      setIsLoading(true);
      ResourceClient.updateEmployeeOtherDetails(updateReview)
        .then((result) => {
          showSnackbar(result, 'success');
          updateFetch();
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
  }, [updateReview]);

  const handleRowDataChange = (updatedRows: any[]) => {
    setIsLoading(true);
    const updatedRemarks = updatedRows.map((r) => {
      return { employeeUid: r.row.id, remarks: r.row.remarks, opportunity: r.row.opportunity };
    });
    ResourceClient.updateEmployeeOtherDetails(updatedRemarks)
      .then((result) => {
        showSnackbar(result, 'success');
        updateFetch();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const cols = columns({ isExpanded, handleExpanded, handleReviewChange });
    setTableColumns(cols);
    const updatedTableCols: any = [];
    Object.keys(columnVisibilityModel).forEach((col: string) => {
      if (columnVisibilityModel[col] !== false)
        updatedTableCols.push(dayjs(col).isValid() ? dayjs(col).month() : col);
    });
    setShowColumns(updatedTableCols);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [columnVisibilityModel, isExpanded]);

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };
  return (
    <>
      <CustomizeFilter
        initialValues={initialValues}
        columnNameList={columnResourceForFilterList}
        handleSubmit={handleSubmit}
        handleClearAllFilter={handleClearAllFilter}
        style={styles.buttonWrapper}
        disabled={apiLoading || isLoading}
      />
      <Box className={styles.datatableWrapper}>
        <Datatable
          loading={apiLoading || isLoading}
          rows={tableRows}
          columns={tableColumns} /* eslint-disable react/jsx-boolean-value */
          columnVisibility={true}
          initialSort={initialSort as any}
          editable={true}
          updateRows={handleRowDataChange}
          skipConfirmation
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          showExportButton
          exportedFileName={`Resource_List_${dayjs().format('DD_MMMM')}`}
          showFilterButton={false}
          showRefreshButton={true}
          getUpdatedList={updateFetch}
        />
      </Box>
    </>
  );
};

export default ResourceList;
