import dayjs from 'dayjs';
import { Priority, Status } from './VacancyTypes';
import { getMatchedUids } from '../../utils/utils';

export const getPositionObject = (noOfPositions: { toString: () => any }) =>
  noOfPositions
    ? {
        value: noOfPositions.toString(),
        label: noOfPositions.toString(),
      }
    : null;
export const transformVacancyDataFromUpdateOperation = (params: any) => ({
  designation: params.row.designation || '',
  jobDescription: params.row.jobDescription || [],
  positions: getPositionObject(params.row.noOfPositions),
  priority: Priority.find((element) => element.label === params.row.priority),
  status: Status.find((element) => element.label === params.row.status),
  location: params.row.location || '',
  closureDate: params.row.expectedClosureDate
    ? dayjs(params.row.expectedClosureDate).format('YYYY-MM-DD')
    : null,
  projects: params.row.projects
    ? params.row.projects.split(',').map((item: string) => item.trim())
    : [],
  customers: params.row.customers
    ? params.row.customers.split(',').map((item: string) => item.trim())
    : [],
  remarks: params.row.remarks || '',
  jobDescriptionDetail: params.row.jobDescriptionDetail || '',
  jdDocumentName: params.row.jdDocumentName || '',
});
export const transformGetVacancyResponse = (data: any) => {
  data.forEach((res: any) => {
    res.jobDescription = res.jobDescription.map((item: any) => item.name);
    res.projects = Array.isArray(res.projects)
      ? res.projects.map((project: any) => project.name).join(', ')
      : '';
    res.customers = Array.isArray(res.customers)
      ? res.customers.map((customer: any) => customer.name).join(', ')
      : '';
    res.createdOn = dayjs(res.createdOn).format('DD MMM YYYY');
    res.expectedClosureDate = res.expectedClosureDate
      ? dayjs(res.expectedClosureDate).format('DD MMM YYYY')
      : '';
    res.priority = Priority.find((item) => item.value === res.priority)?.label;
    res.status = Status.find((item) => item.value === res.status)?.label;
  });
  return data;
};

export const updatedRemarks = (updatedRows: any) =>
  updatedRows.map((r: { row: { id: any; remarks: any } }) => ({
    uid: r.row.id,
    remarks: r.row.remarks,
  }));

export const updateVacancyPayload = (
  input: any,
  AllSkills: any,
  customerList: any,
  projectList: any,
) => ({
  designation: input?.designation || '',
  job_description: getMatchedUids(input.jobDescription, AllSkills),
  no_of_positions: input.positions?.value ? parseInt(input.positions.value, 10) : null,
  priority: input?.priority ? input.priority.value : null,
  status: input?.status ? input.status.value : null,
  location: input.location || null,
  expected_closure_date: input?.closureDate || null,
  remarks: input.remarks || null,
  customer_uids: input.customers ? getMatchedUids(input.customers, customerList) : [],
  project_uids: input.projects ? getMatchedUids(input.projects, projectList) : [],
  job_description_detail: input?.jobDescriptionDetail || null,
});

export const addVacancyPayload = (input: any) => ({
  designation: input?.designation || '',
  job_description: input.jobDescription.map((item: any) => item.value),
  no_of_positions: input.positions?.value ? parseInt(input.positions.value, 10) : null,
  priority: input?.priority?.value || null,
  status: input?.status?.value || null,
  location: input?.location || null,
  expected_closure_date: input?.closureDate || null,
  remarks: input?.remarks || null,
  customer_uids: input.customers ? input.customers.map((customer: any) => customer.value) : [],
  project_uids: input.projects ? input.projects.map((project: any) => project.value) : [],
  job_description_detail: input?.jobDescriptionDetail || null,
});
