import { Checkbox, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid';
import {
  dateFromatter,
  dateHyphenFormatter,
  replaceEmpty,
  sortAlphaNumeric,
} from '../../utils/utils';

const dynamicColumns: any = [];

const tableRowTotalField: any = [];

const serialNumber = 'serialNumber';

const renderCellTooltip = (value: string, length: number = 10) => {
  const newVal = replaceEmpty(value);

  return newVal?.length > length ? (
    <Tooltip title={value}>
      <div className="textWrapper">{newVal}</div>
    </Tooltip>
  ) : (
    <div className="textWrapper">{newVal}</div>
  );
};

const columns = [
  {
    field: 'serialNumber',
    type: 'number',
    headerName: 'Sr. No.',
    headerAlign: 'left',
    width: 72,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      return params.formattedValue;
    },
  },
  {
    field: 'employeeId',
    type: 'number',
    headerName: 'Emp. ID',
    headerAlign: 'left',
    flex: 0.75,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      return params.formattedValue;
    },
    sortComparator: sortAlphaNumeric,
  },
  {
    field: 'fullName',
    type: 'string',
    headerName: 'Name',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return <div className="textWrapper">{renderCellTooltip(params.row.fullName, 22)}</div>;
    },
  },
  {
    field: 'gender',
    type: 'string',
    headerName: 'Gender',
    flex: 0.5,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.gender),
  },
  {
    field: 'dob',
    type: 'string',
    headerName: 'Date of Birth',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) =>
      renderCellTooltip(dateHyphenFormatter(params.row.dob)),
  },
  {
    field: 'age',
    type: 'number',
    headerName: 'Age',
    headerAlign: 'left',
    width: 72,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      return params.row.age;
    },
  },
  {
    field: 'relation',
    type: 'string',
    headerName: 'Relation',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.relation, 22),
  },
];

const getInitialVisibilityModel = ({ showColumns }: any) => {
  const initialVisibilityModelObj: any = {};
  columns.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });

  return initialVisibilityModelObj;
};

const initialShowCols = [
  'employeeId',
  'fullName',
  'relation',
  'dob',
  'gender',
  'age',
  'serialNumber',
];

export {
  serialNumber,
  dynamicColumns,
  tableRowTotalField,
  columns,
  initialShowCols,
  getInitialVisibilityModel,
};
