import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import {
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
  initialSort,
  interviewerUid,
} from './InterviewerListTableConfig';
import { generateRows } from '../../components/Datatable/datatableUtils';
import InterviewClient from '../../api/Interview/interviewAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { GetAllInterviewerParams } from '../../api/Interview/interviewTypes';

interface InterviewerListProps {
  readonly roundName: string;
  readonly selectedList: string;
  readonly selectedTab: string;
  readonly fetchInterviewerList: any;
}

const InterviewerList: React.FC<InterviewerListProps> = ({
  roundName,
  selectedList,
  selectedTab,
  fetchInterviewerList,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const { showSnackbar } = useDisplaySnackbar();
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [tableColumns, setTableColumns] = useState<any[]>(getColumns(selectedList, selectedTab));
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }, selectedList, selectedTab),
  );

  const getSkillNames = (row: any, isPrimary: boolean) => {
    const skillName = row.skills
      .filter((skill: any) => skill.isPrimary === isPrimary)
      .map((skills: any, i: number) => {
        return skills.skillNames.map((skill: any) => {
          return skill.name;
        });
      });
    return skillName[0];
  };

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  const getTableRows = (rowData: any) => {
    const rows: any[] = generateRows(rowData, [], [], interviewerUid);
    const updatedRows: any[] = [];
    rows.forEach((row: any) => {
      const newRow = {
        ...row,
      };
      newRow.primarySkills = getSkillNames(row, true);
      newRow.otherSkills = getSkillNames(row, false);
      updatedRows.push(newRow);
    });
    return updatedRows;
  };

  const generateDatatableInputs = () => {
    const getInterviewerParams = {
      interview_round: roundName,
    };
    setIsLoading(true);
    InterviewClient.getAllInterviewers(getInterviewerParams)
      .then((response: GetAllInterviewerParams[]) => {
        const rows = getTableRows(response);
        setTableRows(rows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    generateDatatableInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchInterviewerList]);

  return (
    <>
      <Datatable
        loading={isLoading}
        rows={tableRows}
        columns={tableColumns} /* eslint-disable react/jsx-boolean-value */
        columnVisibility={true}
        tableHeight="70vh"
        initialSort={initialSort as any}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        showExportButton
        exportedFileName={`Interviewer_List_${dayjs().format('DD_MMMM')}`}
      />
    </>
  );
};

export default InterviewerList;
