import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import Datatable from '../../components/Datatable/Datatable';
import I18nKey from '../../translations/I18nKey';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import {
  generateRows,
  getInitialShowMonthsCols,
  getLastMonths,
} from '../../components/Datatable/datatableUtils';
import {
  initialSort,
  dynamicColumns,
  tableRowTotalField,
  projectIdName,
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
} from './projectListTableConfig';
import ProjectClient from '../../api/Project/projectAPIs';
import {
  DeleteProjectParams,
  GetProjectParams,
  ProjectDetail,
} from '../../api/Project/projectTypes';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { getConvertedResult } from '../../utils/exchangeRates';
import MisDialog from '../../components/MisDialog/MisDialog';
import ProjectDeleteCloseForm from './ProjectDeleteCloseForm';

interface ProjectsActiveProps {
  readonly currentFy: any;
  readonly is_active?: boolean;
}
const ProjectList: React.FC<ProjectsActiveProps> = ({ currentFy, is_active }) => {
  const intl = useIntl();
  const [openDialog, setOpenDialog] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState('');
  const [fetch, setFetch] = useState(0);
  const updateFetch = () => setFetch((fetchNum) => fetchNum + 1);
  const rowActions = [
    {
      label: 'Close',
      logo: <LockIcon />,
      callback: (params: any) => {
        setOpenCloseDialog(true);
        setDeleteProjectId(params.id);
      },
    },
    {
      label: 'Delete',
      logo: <DeleteIcon />,
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteProjectId(params.id);
      },
    },
  ];

  const [tableRows, setTableRows] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>({});
  const [tableColumns, setTableColumns] = useState<any[]>(getColumns({ currentFy }, rowActions));
  const [showColumns, setShowColumns] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const { showSnackbar } = useDisplaySnackbar();
  const [deleteInfo, setDeleteInfo] = useState<DeleteProjectParams>({
    endDate: dayjs().format('YYYY-MM-DD'),
    comment: '',
  });

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, dynamicColumns, tableRowTotalField, projectIdName);
    return rows;
  };

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteProjectId) {
      setLoading(true);
      const { endDate, ...deleteProjectInfo } = deleteInfo;
      ProjectClient.deleteProjectInfo(deleteProjectId, deleteProjectInfo)
        .then((result) => {
          updateFetch();
          const response = result.data;
          if (response?.detail) {
            showSnackbar(result, 'success');
          }
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setLoading(false));
    }
    setDeleteProjectId('');
  };

  const handleCloseOpportunity = () => {
    setOpenCloseDialog(false);
    if (deleteProjectId) {
      setLoading(true);
      const closeInfo = { project: { ...deleteInfo, isActive: false }, project_finance: [] };
      ProjectClient.updateProjectInfo(deleteProjectId, closeInfo)
        .then((result) => {
          updateFetch();
          const response = result.data;
          if (response?.detail) {
            showSnackbar(result, 'success');
          }
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setLoading(false));
    }
    setDeleteProjectId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenCloseDialog(false);
    setDeleteProjectId('');
  };

  useEffect(() => {
    const defaultColumns = [...initialShowCols, ...getInitialShowMonthsCols(4)];
    const updatedColumns = [...initialShowCols, ...getLastMonths(4)];
    if (
      dayjs(currentFy.startDate).isSame(dayjs(), 'year') ||
      dayjs(currentFy.startDate).isAfter(dayjs(), 'year')
    ) {
      setColumnVisibilityModel(getInitialVisibilityModel({ ...currentFy }, defaultColumns));
    } else {
      setColumnVisibilityModel(getInitialVisibilityModel({ ...currentFy }, updatedColumns));
    }
    const columns = getColumns({ currentFy }, rowActions);
    setTableColumns(columns);
    setLoading(true);
    const getProjectParams: GetProjectParams = {
      project_id: '',
      is_active,
      start_date: currentFy.startDate,
      end_date: currentFy.endDate,
      show_total_billing: true,
    };
    ProjectClient.getProjectList(getProjectParams)
      .then((result) => {
        setLoading(false);
        const convertedCurrency = getConvertedResult(result, 'totalBilling');
        const rows = getTableRows(convertedCurrency as ProjectDetail[]);
        setTableRows(rows);
      })
      .catch((e) => showSnackbar(e, 'error'));

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentFy, fetch]);

  useEffect(() => {
    const columns = getColumns({ currentFy, columnVisibilityModel }, rowActions);
    setTableColumns(columns);

    const updatedTableCols: any = [];
    Object.keys(columnVisibilityModel).forEach((col: string) => {
      if (columnVisibilityModel[col] !== false)
        updatedTableCols.push(dayjs(col).isValid() ? dayjs(col).month() : col);
    });
    setShowColumns(updatedTableCols);
  }, [columnVisibilityModel]);

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <>
      <Datatable
        loading={isLoading}
        rows={tableRows}
        columns={tableColumns} /* eslint-disable react/jsx-boolean-value */
        columnVisibility={true}
        tableHeight="70vh"
        initialSort={initialSort as any}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        showExportButton
        exportedFileName={`Project_List_${dayjs().format('DD_MMMM')}`}
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.PROJECT_DETAIL_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.PORJECT_DETAIL_REMOVE_PROJECT,
        })}
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        disableSubmitBtn={!deleteInfo}
        actionBtnLabel="Delete"
        additionalInfoSection={
          <ProjectDeleteCloseForm setDeleteInfo={setDeleteInfo} isProjectCloseForm={false} />
        }
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.PROJECT_DETAIL_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.PORJECT_DETAIL_CLOSE_PROJECT,
        })}
        isOpen={openCloseDialog}
        handleSuccess={handleCloseOpportunity}
        handleClose={handleClose}
        disableSubmitBtn={!deleteInfo}
        actionBtnLabel="Close Project"
        additionalInfoSection={
          <ProjectDeleteCloseForm setDeleteInfo={setDeleteInfo} isProjectCloseForm={true} />
        }
      />
    </>
  );
};

ProjectList.defaultProps = {
  is_active: false,
};

export default ProjectList;
