import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import I18nKey from '../../translations/I18nKey';
import MisTabs from '../../components/MisTabs/MisTabs';
import CustomerRelationClient from '../../api/Opportunity/opportunityAPIs';
import CustomerRelations from './Opportunities';
import { AllOpportunities } from '../../api/Opportunity/opportunityTypes';
import { generateRows } from '../../components/Datatable/datatableUtils';
import { getListOfMonths, getYearQuarter, getFYDateRange } from '../../utils/utils';
import { dynamicColumns, tableRowTotalField, opportunityIdName } from './opportunityTableConfig';
import useAllCustomerRelationsStyles from './AllOpportunitiesStyles';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { F0, F1, F2, F3, F4 } from '../../utils/oppStages';
import { getConvertedResult } from '../../utils/exchangeRates';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';

interface CustomerRelationsTabs {
  readonly label: string;
  readonly component: React.ReactNode;
}

const currFYDateRange = getFYDateRange(dayjs());

const AllCustomerRelations: React.FC = () => {
  const allCustomerRelationsStyles: any = useAllCustomerRelationsStyles();
  const intl = useIntl();
  const [tableRows, setTableRows] = useState<Record<string, any[]>>();
  const [isLoading, setIsLoading] = useState(false);

  const { showSnackbar } = useDisplaySnackbar();

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, dynamicColumns, tableRowTotalField, opportunityIdName);
    return rows;
  };

  const getRevenueUpdatedRows = (rows: any) => {
    const updatedTableRows: Record<string, any[]> = {
      all: [],
      [F1]: [],
      [F2]: [],
      [F3]: [],
      [F4]: [],
    };

    const monthsList = getListOfMonths(currFYDateRange);

    rows.forEach((row: any) => {
      const newRow: any = { ...row };

      const obj: Record<string, number> = {};
      monthsList.forEach((month: string) => {
        const quarter = getYearQuarter(dayjs(month).month());
        obj[`revenueQ${quarter}`] =
          (Number(obj[`revenueQ${quarter}`]) || 0) + (Number(row[month]) || 0);
      });
      Object.assign(newRow, obj);

      if (Object.keys(updatedTableRows).includes(newRow.stage))
        updatedTableRows[newRow.stage as string].push(newRow);
      if (newRow.stage !== F0) updatedTableRows.all.push(newRow);
    });

    return updatedTableRows;
  };

  const getCustomerRelationList = () => {
    setIsLoading(true);

    CustomerRelationClient.getCustomerRelationList()
      .then((data: AllOpportunities[]) => {
        const convertedCurrency = getConvertedResult(data, 'forecastedRevenue');
        const rows = getTableRows(convertedCurrency);
        const revenueUpdatedRows = getRevenueUpdatedRows(rows);
        setTableRows(revenueUpdatedRows);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getCustomerRelationList();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const customerRelationsTabs: CustomerRelationsTabs[] = [
    {
      label: `All ${tableRows?.all ? `(${tableRows.all.length})` : ''}`,
      component: (
        <CustomerRelations
          apiLoading={isLoading}
          rows={tableRows?.all || []}
          onCreateOpportunity={getCustomerRelationList}
        />
      ),
    },
    {
      label: `${F4.toUpperCase()} ${tableRows?.[F4] ? `(${tableRows?.[F4].length})` : ''}`,
      component: (
        <CustomerRelations
          apiLoading={isLoading}
          stage={F4}
          rows={tableRows?.[F4] || []}
          onCreateOpportunity={getCustomerRelationList}
        />
      ),
    },
    {
      label: `${F3.toUpperCase()} ${tableRows?.[F3] ? `(${tableRows?.[F3].length})` : ''}`,
      component: (
        <CustomerRelations apiLoading={isLoading} stage={F3} rows={tableRows?.[F3] || []} />
      ),
    },
    {
      label: `${F2.toUpperCase()} ${tableRows?.[F2] ? `(${tableRows?.[F2].length})` : ''}`,
      component: (
        <CustomerRelations apiLoading={isLoading} stage={F2} rows={tableRows?.[F2] || []} />
      ),
    },
    {
      label: `${F1.toUpperCase()} ${tableRows?.[F1] ? `(${tableRows?.[F1].length})` : ''}`,
      component: (
        <CustomerRelations apiLoading={isLoading} stage={F1} rows={tableRows?.[F1] || []} />
      ),
    },
  ];

  return (
    <Box className={allCustomerRelationsStyles.opportunitiesWrapper}>
      <Box className={allCustomerRelationsStyles.Header}>
        <span>
          {intl.formatMessage({
            id: I18nKey.CUSTOMER_RELATION_TITLE,
          })}
        </span>
      </Box>
      <div className={allCustomerRelationsStyles.allCustomerRelationsWrapper}>
        <MisTabs tabs={customerRelationsTabs} />
      </div>
    </Box>
  );
};

export default AllCustomerRelations;
