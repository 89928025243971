import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Datatable from '../../components/Datatable/Datatable';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import {
  generateRows,
  getInitialShowMonthsCols,
  getLastMonths,
  getShowMonthCols,
} from '../../components/Datatable/datatableUtils';
import {
  initialSort,
  dynamicColumns,
  revenueEmployeeIdName,
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
} from './projectResourceTableConfig';
import {
  RevenueEmployee,
  UpdateProjectResourceRevenueParams,
} from '../../api/Revenue/revenueTypes';
import RevenueClient from '../../api/Revenue/revenueAPIs';
import { InvoiceStatus } from '../../api/Invoice/invoiceTypes';
import { getFYDateRange } from '../../utils/utils';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import MisDialog from '../../components/MisDialog/MisDialog';
import DeleteInfo from '../Project/DeleteInfo';
import I18nKey from '../../translations/I18nKey';
import { DeleteProjectResourceParams } from '../../api/Project/projectTypes';
import { ResourceDetailInfo } from './forms/ResourceDetailInfo';
import ResourceClient from '../../api/Resource/resourceAPIs';

interface ProjectResourceRevenueProps {
  readonly project_id: string;
  readonly projectResourceList: RevenueEmployee[];
  readonly callApiRevenueProjectDetail: () => void;
  readonly invoiceStatus: InvoiceStatus;
  readonly isEditable?: boolean;
  readonly currentDateRange: any;
  readonly currency: string;
  readonly accountType: string;
}

const ProjectResourceRevenue: React.FC<ProjectResourceRevenueProps> = ({
  project_id,
  projectResourceList,
  callApiRevenueProjectDetail,
  isEditable,
  invoiceStatus,
  currentDateRange,
  currency,
  accountType,
}) => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>({});
  const [showColumns, setShowColumns] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState<DeleteProjectResourceParams>({
    endDate: dayjs().format('YYYY-MM-DD'),
    comments: '',
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [deleteEmployeeId, setDeleteEmployeeId] = useState('');
  const [selectedEmpId, setSelectedEmpId] = useState('');
  const [resourceDetailsInfo, setResourceDetailsInfo] = useState<any>();
  const resourceDetailsFormRef = useRef<any>();
  const [isDetailsFormValid, setIsDetailsFormValid] = useState<boolean>(false);
  const rowActions = [
    // {
    //   label: 'Delete',
    //   logo: <DeleteIcon />,
    //   callback: (params: any) => {
    //     setOpenDialog(true);
    //     setDeleteEmployeeId(params.id);
    //   },
    // },
    {
      label: 'Edit',
      logo: <EditIcon />,
      callback: (params: any) => {
        setOpenEditDialog(true);
        setIsDetailsFormValid(false);
        setSelectedEmpId(params.id);
        setResourceDetailsInfo({
          allocationPercentage: params.row.allocationPercentage,
          billingRate: params.row.billingRate,
          startDate: params.row.startDate,
          endDate: params.row.endDate,
        });
      },
    },
  ];
  const [tableColumns, setTableColumns] = useState<any[]>(
    getColumns({
      currentDateRange,
      columnVisibilityModel,
      invoiceStatus,
      accountType,
      rowActions,
    }),
  );

  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const params = useParams();
  const { id } = params;

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(data, dynamicColumns, [], revenueEmployeeIdName);
    return rows;
  };

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteEmployeeId) {
      setIsLoading(true);
      RevenueClient.deleteProjectResource(project_id, deleteEmployeeId, deleteInfo)
        .then((result) => {
          callApiRevenueProjectDetail();
          if (result?.details) {
            showSnackbar(result, 'success');
          }
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteEmployeeId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenEditDialog(false);
    setDeleteEmployeeId('');
    setSelectedEmpId('');
  };

  useEffect(() => {
    const currentColums = [
      ...initialShowCols,
      ...getShowMonthCols(currentDateRange.startDate, currentDateRange.endDate),
    ];
    setColumnVisibilityModel(getInitialVisibilityModel({ ...currentDateRange }, currentColums));
    const columns = getColumns({
      currentDateRange,
      columnVisibilityModel,
      invoiceStatus,
      currency,
      accountType,
      rowActions,
    });
    setTableColumns(columns);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentDateRange, invoiceStatus]);

  useEffect(() => {
    const columns = getColumns({
      currentDateRange,
      columnVisibilityModel,
      invoiceStatus,
      currency,
      accountType,
      rowActions,
    });
    setTableColumns(columns);
    const updatedTableCols: any = [];
    Object.keys(columnVisibilityModel).forEach((col: string) => {
      if (columnVisibilityModel[col] !== false)
        updatedTableCols.push(dayjs(col).isValid() ? dayjs(col).month() : col);
    });
    setShowColumns(updatedTableCols);
  }, [columnVisibilityModel]);

  useEffect(() => {
    const rows = getTableRows(projectResourceList as RevenueEmployee[]);
    setTableRows(rows);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [projectResourceList]);

  const handleRowUpdate = (updatedRows: any[], newRows: any[], finalize: boolean) => {
    setIsLoading(true);

    // const currentMonth = dayjs().startOf('month').format('YYYY-MM-DD');

    // let updateresourceRevenues: UpdateProjectResourceRevenueParams[] = [];

    // if (finalize) {
    //   updateresourceRevenues = newRows.map((updatedRow) => {
    //     return {
    //       employeeUid: updatedRow.employeeUid,
    //       billingDate: currentMonth,
    //       revenue: updatedRow[currentMonth]?.amount,
    //       comment: updatedRow[currentMonth]?.comment,
    //     };
    //   });
    // } else {
    //   updateresourceRevenues = updatedRows.map((updatedRow) => {
    //     return (
    //       updatedRow.updatedFields.includes(currentMonth) && {
    //         employeeUid: updatedRow.row?.employeeUid,
    //         billingDate: updatedRow.updatedFields.includes(currentMonth) ? currentMonth : undefined,
    //         revenue: updatedRow.updatedFields.includes(currentMonth)
    //           ? updatedRow.row[currentMonth]?.amount
    //           : undefined,
    //         comment: updatedRow.row[currentMonth]?.comment,
    //       }
    //     );
    //   });
    // }

    // Todo: Uncomment above lines 91 - 117 and remove lines 120 - 131 as this is temporary update call to support previous month update
    const updateresourceRevenues: UpdateProjectResourceRevenueParams[] = [];

    updatedRows.forEach((updatedRow) => {
      updatedRow.updatedFields.forEach((field: any) => {
        updateresourceRevenues.push({
          employeeUid: updatedRow.row?.employeeUid,
          billingDate: field,
          revenue: updatedRow.row[field]?.amount,
          comment: updatedRow.row[field]?.comment,
          noOfHours: updatedRow.row[field]?.noOfHours,
        });
      });
    });

    RevenueClient.updateProjectResourceRevenue(project_id, updateresourceRevenues)
      .then((result) => {
        if (result) {
          callApiRevenueProjectDetail();
          showSnackbar(result, 'success');
        }
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  const handleUpdate = () => {
    setOpenEditDialog(false);
    setIsLoading(true);
    const values = resourceDetailsFormRef.current?.values;
    const updatedFormValues = {
      allocationPercentage: values.allocationPercentage || 0,
      billingRate: values.billingRate || 0,
      startDate: values.startDate,
      endDate: values.endDate,
    };
    ResourceClient.updateResourceProject(selectedEmpId, id, updatedFormValues)
      .then((res) => {
        showSnackbar(res, 'success');
        callApiRevenueProjectDetail();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Datatable
        loading={isLoading}
        rows={tableRows}
        columns={tableColumns}
        initialSort={initialSort as any}
        tableHeight="50vh"
        /* eslint-disable react/jsx-boolean-value */
        editable={isEditable}
        updateRows={handleRowUpdate}
        showTotalRowFooter={true}
        showFinalizeButtton={true}
        columnVisibility={true}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.RESOURCE_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.RESOURCE_DETAILS_UNASSIGN,
        })}
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel={intl.formatMessage({
          id: I18nKey.BUTTON_LABEL_UNASSIGN,
        })}
        additionalInfoSection={<DeleteInfo setDeleteInfo={setDeleteInfo} />}
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.RESOURCE_UPDATE_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.RESOURCE_DETAILS_UPDATE,
        })}
        isOpen={openEditDialog}
        handleSuccess={handleUpdate}
        handleClose={handleClose}
        actionBtnLabel={intl.formatMessage({
          id: I18nKey.BUTTON_LABEL_UPDATE,
        })}
        disableSubmitBtn={!isDetailsFormValid}
        additionalInfoSection={
          <ResourceDetailInfo
            resourceDetailsInfo={resourceDetailsInfo}
            resourceDetailsFormRef={resourceDetailsFormRef}
            setIsDetailsFormValid={setIsDetailsFormValid}
          />
        }
      />
    </>
  );
};

ProjectResourceRevenue.defaultProps = {
  isEditable: false,
};

export default ProjectResourceRevenue;
