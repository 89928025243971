import React, { useState } from 'react';
import Datatable from '../../components/Datatable/Datatable';
import { columns, initialSort } from './AuditLogsListTableConfig';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

interface AuditLogsListProps {
  readonly tableRows: any[];
  readonly apiLoading: boolean;
}

const AuditLogsList: React.FC<AuditLogsListProps> = ({ tableRows, apiLoading }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Datatable loading={isLoading || apiLoading} rows={tableRows} columns={columns} />
    </>
  );
};
export default AuditLogsList;
