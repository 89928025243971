import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => {
  return {
    fabContainer: {
      position: 'fixed',
      bottom: '32px',
      right: '15px',
      zIndex: 100,
    },

    iconbutton: {
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      background: `linear-gradient(180deg, #0092FF 0%, ${theme.palette.secondary.main} 100%);`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        fontSize: '25px',
        display: 'flex',
      },
    },
    button: {
      width: '60px',
      height: '60px',

      '& svg': {
        WebkitTransition: '-webkit-transform 300ms',
        transition: 'transform 300ms',
        WebkitTransform: `rotate(0deg)`,
        transform: `rotate(0deg)`,
        fontSize: '35px',
      },
    },
    options: {
      listStyleType: 'none',
      position: 'absolute',
      bottom: '55px',
      right: 0,
      width: '225px',
      WebkitTransition: '-webkit-transform 300ms',
      transition: 'transform 300ms',
      paddingLeft: '0 !important',

      '& li': {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '5px',
        margin: '5px',
        borderRadius: 8,
        background: theme.palette.primary.main,
        boxShadow: `0px 0px 8px ${theme.palette.shadow?.main}`,
      },
    },
    btnLabel: {
      padding: '2px 5px',
      marginRight: '10px',
      alignSelf: 'center',
      borderRadius: '3px',
    },
    inverted: {
      '& svg': {
        WebkitTransition: '-webkit-transform 300ms',
        transition: 'transform 300ms',
        WebkitTransform: `rotate(135deg)`,
        transform: `rotate(135deg)`,
      },
    },
    formContainer: {
      '& form > button': {
        position: 'absolute',
        bottom: 7,
        right: 12,
      },
    },
    customerPOCBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    alert: {
      paddingBottom: 10,
      '& svg': {
        cursor: 'pointer',
      },
    },
    addBtn: {
      float: 'right',
      zIndex: '1',
      fontSize: '0.7rem !important',
    },
    gridItem: {
      marginTop: '-19px !important',
      paddingTop: '0 !important',
    },
  };
});
