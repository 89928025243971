import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import Datatable from '../../components/Datatable/Datatable';
import MisDialog from '../../components/MisDialog/MisDialog';
import {
  generateRows,
  generateDeleteColumn,
  getInitialShowMonthsCols,
  getLastMonths,
} from '../../components/Datatable/datatableUtils';
import {
  initialSort,
  dynamicColumns,
  getTableRowTotalField,
  resourceIdName,
  getColumns,
  getInitialVisibilityModel,
  initialShowCols,
} from './projectResourcesTableConfig';
import ProjectClient, { PATHS } from '../../api/Project/projectAPIs';
import {
  DeleteProjectResourceParams,
  GetProjectResourceParams,
  ProjectResource,
  UpdateProjectResourceParams,
} from '../../api/Project/projectTypes';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import DeleteInfo from './DeleteInfo';
import I18nKey from '../../translations/I18nKey';
import { ApiOperations, calculateTotalRevenue, getFYDateRange } from '../../utils/utils';
import { GlobalContext } from '../../contexts/GlobalContext';

interface ResourceListProps {
  readonly project_id: string;
  readonly isEditable?: boolean;
  readonly accountType: string;
  readonly setTotalRevenue: (total: string) => void;
  readonly setTotalResources: (total: number) => void;
  readonly currentFy: any;
  readonly currency: any;
}

const currFYDateRange = getFYDateRange(dayjs());

const Resources: React.FC<ResourceListProps> = ({
  project_id,
  isEditable,
  accountType,
  setTotalRevenue,
  currentFy,
  setTotalResources,
  currency,
}) => {
  const intl = useIntl();

  const [tableRows, setTableRows] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>({});

  const [showColumns, setShowColumns] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteEmployeeId, setDeleteEmployeeId] = useState('');
  const { showSnackbar } = useDisplaySnackbar();
  const [deleteInfo, setDeleteInfo] = useState<DeleteProjectResourceParams>({
    endDate: dayjs().format('YYYY-MM-DD'),
    comments: '',
  });

  const rowActions = [
    {
      label: 'Delete',
      logo: <DeleteIcon />,
      callback: (params: any) => {
        setOpenDialog(true);
        setDeleteEmployeeId(params.id);
      },
    },
  ];

  const { checkAccess } = useContext(GlobalContext);

  const getTableColumns = () => {
    const configColumns: any[] = getColumns({
      accountType,
      currentFy,
      currency,
    });
    const actionColumns: any[] = checkAccess(
      PATHS.DELETE_PROJECT_RESOURCE('id', 'id'),
      ApiOperations.DELETE,
    )
      ? generateDeleteColumn(rowActions)
      : [];
    return [...configColumns, ...actionColumns];
  };
  const [tableColumns, setTableColumns] = useState<any[]>(getTableColumns());

  const getTableRows = (data: any) => {
    const rows: any[] = generateRows(
      data,
      dynamicColumns,
      getTableRowTotalField(currency),
      resourceIdName,
    );
    return rows;
  };

  const callApiProjectResources = () => {
    setIsLoading(true);
    const getProjectResourceParams: GetProjectResourceParams = {
      project_id,
      start_date: currentFy.startDate,
      end_date: currentFy.endDate,
      show_total_billing: true,
    };
    ProjectClient.getProjectResources(getProjectResourceParams)
      .then((data) => {
        const result: ProjectResource[] = data;
        const rows = getTableRows(result);
        setTableRows(rows);
        if (_.isEqual(currentFy, currFYDateRange)) {
          const { totalCurrRevenue } = calculateTotalRevenue(
            data || [],
            'totalBilling',
            data[0]?.currency,
          );
          setTotalRevenue(totalCurrRevenue);
        }
        setTotalResources(data.length);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    callApiProjectResources();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentFy]);

  useEffect(() => {
    const defaultColumns = [...initialShowCols, ...getInitialShowMonthsCols(4)];
    const updatedColumns = [...initialShowCols, ...getLastMonths(4)];
    if (
      dayjs(currentFy.startDate).isSame(dayjs(), 'year') ||
      dayjs(currentFy.startDate).isAfter(dayjs(), 'year')
    ) {
      setColumnVisibilityModel(
        getInitialVisibilityModel({ ...currentFy, showColumns: defaultColumns }),
      );
    } else {
      setColumnVisibilityModel(
        getInitialVisibilityModel({ ...currentFy, showColumns: updatedColumns }),
      );
    }
    const columnsList = getTableColumns();
    setTableColumns(columnsList);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [accountType, currentFy]);

  const handleDelete = () => {
    setOpenDialog(false);
    if (deleteEmployeeId) {
      setIsLoading(true);
      ProjectClient.deleteProjectResource(project_id, deleteEmployeeId, deleteInfo)
        .then((result) => {
          callApiProjectResources();
          if (result?.details) {
            showSnackbar(result, 'success');
          }
        })
        .catch((e) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
    setDeleteEmployeeId('');
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDeleteEmployeeId('');
  };

  const handleRowUpdate = (updatedRows: any[], newRows: any[]) => {
    setIsLoading(true);
    const updatedResources: UpdateProjectResourceParams[] = updatedRows.map((updatedRow) => {
      return {
        empUid: updatedRow.row.employeeUid,
        billingRate: updatedRow.updatedFields.includes('billingRate')
          ? updatedRow.row.billingRate
          : undefined,
        allocationPercentage: updatedRow.updatedFields.includes('allocationPercentage')
          ? updatedRow.row.allocationPercentage
          : undefined,
      };
    });
    ProjectClient.updateProjectResources(project_id, updatedResources)
      .then((result) => {
        if (result?.data.detail) {
          callApiProjectResources();
          setTableRows(newRows);
          showSnackbar(result, 'success');
        }
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const columns = getTableColumns();
    setTableColumns(columns);

    const updatedTableCols: any = [];
    Object.keys(columnVisibilityModel).forEach((col: string) => {
      if (columnVisibilityModel[col] !== false)
        updatedTableCols.push(dayjs(col).isValid() ? dayjs(col).month() : col);
    });
    setShowColumns(updatedTableCols);
  }, [columnVisibilityModel]);

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };
  return (
    <>
      <Datatable
        loading={isLoading}
        rows={tableRows}
        columns={tableColumns}
        initialSort={initialSort as any}
        tableHeight={tableRows?.length ? '55vh' : '230px'}
        /* eslint-disable react/jsx-boolean-value */
        editable={isEditable && checkAccess(PATHS.PUT_PROJECT_RESOURCES('id'), ApiOperations.PUT)}
        updateRows={handleRowUpdate}
        columnVisibility={true}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      />
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.RESOURCE_TITLE,
        })}
        message={intl.formatMessage({
          id: I18nKey.RESOURCE_DETAILS_UNASSIGN,
        })}
        isOpen={openDialog}
        handleSuccess={handleDelete}
        handleClose={handleClose}
        actionBtnLabel={intl.formatMessage({
          id: I18nKey.BUTTON_LABEL_UNASSIGN,
        })}
        additionalInfoSection={<DeleteInfo setDeleteInfo={setDeleteInfo} />}
      />
    </>
  );
};

Resources.defaultProps = {
  isEditable: false,
};

export default Resources;
