/* eslint-disable react/no-array-index-key */
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { replaceEmpty } from '../../utils/utils';

const dynamicColumns: any = [];

const tableRowTotalField: any = [];
const contactId = 'uid';

const initialSort = { field: 'createdOn', sort: 'desc' };

const renderCellTooltip = (value: string, length: number = 10) => {
  const newVal = replaceEmpty(value);

  return newVal?.length > length ? (
    <Tooltip title={value}>
      <div className="textWrapper">{newVal}</div>
    </Tooltip>
  ) : (
    <div className="textWrapper">{newVal}</div>
  );
};

const getColumns = (rowActions: any[]) => [
  {
    field: 'designation',
    type: 'string',
    headerName: 'Job Title',
    flex: 1.5,
  },
  {
    field: 'jobDescription',
    type: 'string',
    headerName: 'Skills',
    flex: 2,
    hideable: true,
    hide: true,
    sortable: false,
    cellClassName: 'resource-skill-cell',
    renderCell: (params: GridRenderCellParams) => {
      const cellDisplay = params?.value?.map((skill: string, index: number) => (
        <div className="resource-skill" key={skill + index}>
          {skill}
        </div>
      ));
      return <div style={{ display: 'flex', flexWrap: 'wrap' }}>{cellDisplay}</div>;
    },
  },
  {
    field: 'createdBy',
    type: 'string',
    headerName: 'Owner',
    flex: 2,
  },
  {
    field: 'jobDescriptionDetail',
    type: 'string',
    headerName: 'Job Description',
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) =>
      renderCellTooltip(params.row.jobDescriptionDetail, 32),
  },
  {
    field: 'noOfPositions',
    type: 'string',
    headerName: 'No Of Positions',
    flex: 1,
  },
  {
    field: 'priority',
    type: 'string',
    headerName: 'Priority',
    flex: 0.5,
  },
  {
    field: 'expectedClosureDate',
    type: 'string',
    headerName: 'Expected Closure Date',
    flex: 1,
  },
  {
    field: 'status',
    type: 'string',
    headerName: 'Status',
    flex: 0.5,
  },
  {
    field: 'location',
    type: 'string',
    headerName: 'Location',
    flex: 0.5,
  },
  {
    field: 'createdOn',
    type: 'string',
    headerName: 'Created On',
    flex: 1,
  },
  {
    field: 'projects',
    type: 'string',
    headerName: 'Projects',
    flex: 0.8,
  },
  {
    field: 'customers',
    type: 'string',
    headerName: 'Customers',
    flex: 1.5,
  },
  {
    field: 'remarks',
    type: 'string',
    headerName: 'Remarks',
    flex: 1.3,
    editable: true,
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    cellClassName: 'delete-action',
    align: 'center',
    flex: 1,
    getActions: (params: any) => {
      let isDisabled = false;
      return rowActions.map((action) => {
        if (params?.row?.jdPresignedUrl === null && action.label === 'Download') isDisabled = true;
        return (
          <GridActionsCellItem
            key={action.label}
            icon={action.logo}
            label={action.label}
            disabled={Boolean(isDisabled)}
            onClick={() => action.callback(params)}
          />
        );
      });
    },
  },
];

const getInitialVisibilityModel = ({ showColumns }: any, rowActions: any[]) => {
  const initialVisibilityModelObj: any = {};
  const columns = getColumns(rowActions);
  columns.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });

  return initialVisibilityModelObj;
};

const initialShowCols = [
  'designation',
  'createdBy',
  'priority',
  'status',
  'remarks',
  'customers',
  'jobDescription',
  'actions',
];

export {
  contactId,
  dynamicColumns,
  tableRowTotalField,
  getColumns,
  initialShowCols,
  getInitialVisibilityModel,
  initialSort,
};
